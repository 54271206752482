//@ts-ignore
export default {
  oliverpeoples: {
    en_US: {
      steps: {
        tilesTitlePrefixProceed: "Proceed with",
        tilesTitlePrefixUpgrade: "Upgrade to",
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            addPrismValues: "Add prism values",
            pupillaryDistanceSubtitle: "You can select the default settings of <b>61 for women</b> and <b>64 for men</b> if you have an average or low prescription. If you have a strong prescription (or if you want to know your exact PD), please ",
            whatIsIt: "What is it?",
            commentsTitle: "Comments",
            alerts: {
              axisRangeError: "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError: "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning: "You’ve inserted 1 positive and 1 negative value, which is very rare. \nWe suggest double checking your prescription. If this is correct, you can proceed.",
              pdValueWarning: "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              requiredToProceed: "Checking this box is required to proceed.",
              incompatibleWithPrescriptionBody: "but don’t worry, compatible frames are available",
              agreementText: "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist."
            },
            continueModal: {
              title: "Send prescription later",
              message: "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back"
            },
            digitalOptometry: {
              title: "How to measure your pupillary distance",
              appSubtitle:
                "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. It indicates exactly which part of the lens you look through, ensuring optimal comfort and clarity. You can measure with the app or manually.",
              manuallySubtitle:
                "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. It indicates exactly which part of the lens you look through, ensuring optimal comfort and clarity. You can measure with the app or manually.",
              toggle: {
                app: "With the App",
                manually: "Manually",
              },
              opthyDesc:
                "<b>Get your glasses just right with Opthy.</b><br/><p>Opthy measures your Pupillary Distance, allowing you to find the most comfortable, personalized fit.</p><p>App available on iPhone X and above.</p>",
              manuallyContent:
                "<div>If you want, you can measure your PD yourself by following these steps:</div><ul><li>Grab a small ruler in one hand, and have your pencil and paper handy.</li><li>Now position yourself approximately 8 inches (20 cm) away from a mirror.</li><li>Close your left eye and align the 0 over the center of your right pupil.</li><li>Measure the distance from your right to your left pupil.</li><li>The number that lines up directly over your left pupil is your PD (An average PD for an adult ranges between 58 and 65).</li></ul>",
            },
            incompatibleFrame:
              "We're sorry – the frame you’ve chosen isn't compatible with your prescription. Please select another style.\n </br>Have questions? You can <a href='https://www.oliverpeoples.com/usa/contact-us'>contact our Customer Service</a> team.",
          },
          account: {
            card: {
              description: "Log in and select your prescription."
            },
            review: {
              title: "Loaded from the account"
            }
          },
          upload: {
            card: {
              description: "And we'll take care of the rest. We accept the following file formats: ###FILE_FORMATS### (max ###FILE_SIZE### MB) and IWork pages"
            },
            review: {
              title: "Uploaded"
            },
            closeLabel: "Close",
            prescriptionUploaded: "Prescription Uploaded",
            pdForm: {
              pdValueWarning: "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch."
            },
            filePreviewTitle: "Your prescription"
          },
          doctor: {
            review: {
              title: "We'll call your doctor"
            },
            pdForm: {
              pdValueWarning: "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch."
            }
          },
          later: {
            card: {
              description: "We'll ask for it after you order, either by uploading it or having us call your doctor."
            }
          },
          californian: {
            toggle: "Are you a California resident?",
            info: "Please note that besides adding prescription values manually, California residents are also required to electronically transmit their valid prescription. Choose an option to proceed.",
            chooseTitle: "CHOOSE HOW TO SEND YOUR PRESCRIPTION",
            uploadTitle: "Upload",
            uploadDesc: "We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) and IWork pages.",
            uploadButton: "Upload",
            callMyDoc: "Call my doctor",
            callMyDocButton: "FIND YOUR DOCTOR",
            yes: "YES",
            no: "NO",
            modalMissingPrescription: {
              title: "Missing prescription values",
              subtitle: "If you don’t fill the values we won’t be able to suggest the right lens for you",
              bottonYes: "YES, CONTINUE",
              bottonNo: "NO, GO BACK"
            }
          }
        },
        type: {
          moreInfo: "More info"
        },
        treatmentsFamily: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included."
        },
        treatments: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included."
        },
        color: {
          polarized: "Polarized only",
          tooltip: {
            polarized: "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            blueLight: "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue"
          }
        },
        addOns: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included."
        },
        review: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology.",
          paymentInstallments: {
            installmentsLabel: "Pay over time in interest-free installments with&nbsp ###PAYMENT_TYPE###",
            learnMore: "Learn more",
            modal: {
              title: "Paying in installments",
              subtitle: "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.",
              affirm: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments every two weeks with zero hidden fees. Payment options are offered by ###PAYMENT_TYPE###, are subject to an eligibility check, and may not be available in all states. CA residents: Loans by ###PAYMENT_TYPE### Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license."
            }
          },
          tooltip: "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store."
        }
      },
      price: {
        vatIncluded: "Tax included"
      },
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      frameSize: "Frame size",
      yourSelections: "Your selections",
      generic: {
        modal: {
          close: "close"
        }
      }
    },
    en_GB: {
      steps: {
        advancedPrescription: {
          manual: {
            whatIsIt: 'What is it?',
            alerts: {
              sphPositiveNegativeWarning: 'You’ve inserted 1 positive and 1 negative value, which is very rare. \nWe suggest you double check your prescription. If this is correct, you can proceed.',
            },
          },
          upload: {
            card: {
              title: 'Upload now',
              description: 'And we\'ll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages',
            },
          },
          later: {
            card: {
              description: 'We\'ll ask for it after you order, either by uploading it or having us call your optician.',
            },
          },
        },
        treatmentsFamily: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        thickness: {
          designTypeTitle: 'Choose your lens design',
        },
        transitionColor: {
          indoor: 'Indoor',
          outdoor: 'Outdoor',
        },
        color: {
          tooltip: {
            'switch': 'Reduces glare in extra bright light, for superior clarity and eye protection.',
            polarized: 'Reduces glare in extra bright light, for superior clarity and eye protection.',
            blueLight: 'Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue',
          },
        },
        addOns: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included',
        },
        review: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology.',
          paymentInstallments: {
            installmentsLabel: 'Pay over time in interest-free instalments with ###PAYMENT_TYPE###',
            modal: {
              title: 'Paying in instalments',
              subtitle: 'Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free instalments, with no hidden fees.',
              klarna: 'With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###',
            },
          },
          tooltip: 'Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.',
        },
      },
      yourSelections: 'Your selections',
    },
    en_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            whatIsIt: 'What is it?',
            alerts: {
              sphPositiveNegativeWarning: 'You’ve inserted 1 positive and 1 negative value, which is very rare. \nWe suggest you double check your prescription. If this is correct, you can proceed.',
            },
          },
          upload: {
            card: {
              title: 'Upload now',
              description: 'And we\'ll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages',
            },
          },
          later: {
            card: {
              description: 'We\'ll ask for it after you order, either by uploading it or having us call your optician.',
            },
          },
        },
        treatmentsFamily: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        thickness: {
          designTypeTitle: 'Choose your lens design',
        },
        transitionColor: {
          indoor: 'Indoor',
          outdoor: 'Outdoor',
        },
        color: {
          tooltip: {
            'switch': 'Reduces glare in extra bright light, for superior clarity and eye protection.',
            polarized: 'Reduces glare in extra bright light, for superior clarity and eye protection.',
            blueLight: 'Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue',
          },
        },
        addOns: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included',
        },
        review: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology.',
          paymentInstallments: {
            installmentsLabel: 'Pay over time in interest-free instalments with ###PAYMENT_TYPE###',
            modal: {
              title: 'Paying in instalments',
              subtitle: 'Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free instalments, with no hidden fees.',
              klarna: 'With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###',
            },
          },
          tooltip: 'Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.',
        },
      },
      yourSelections: 'Your selections',
    },
    en_MX: {
      steps: {
        advancedPrescription: {
          manual: {
            alerts: {
              sphPositiveNegativeWarning: 'You’ve inserted 1 positive and 1 negative value, which is very rare. \nWe suggest double checking your prescription. If this is correct, you can proceed.',
            },
          },
          upload: {
            card: {
              description: 'And we\'ll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages',
            },
            fileTooBigErrorTitle: 'EL ARCHIVO ES DEMASIADO PESADO',
            fileTooBigErrorDescription: 'Tu archivo es demasiado pesado. Considera ###FILE_SIZE### máximo',
            uploadDifferentFile: 'SUBIR UN ARCHIVO DISTINTO',
            changeMethod: 'Cambiar el método de envío',
            prescriptionUploaded: 'RECETA CARGADA',
          },
        },
        brand: {
          confirmedTitle: 'Lens brand:',
        },
        treatmentsFamily: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        thickness: {
          title: 'Selecciona el grosor de tus cristales',
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        treatments: {
          addToBag: 'Agregar a la cesta',
        },
        transitionColor: {
          indoor: 'Indoor',
          outdoor: 'Outdoor',
        },
        color: {
          tooltip: {
            'switch': 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            polarized: 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            blueLight: 'Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue',
          },
        },
        addOns: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.',
        },
        review: {
          title: 'Review your selections',
          subtitle: 'Your glasses will come with best in class Ray-Ban technology.',
          paymentInstallments: {
            installmentsLabel: 'Pay over time in interest-free installments with ###PAYMENT_TYPE###',
            modal: {
              title: 'Paying in installments',
              subtitle: 'Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.',
            },
          },
          tooltip: 'Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.',
        },
      },
      price: {
        totalFrameLens: {
          frameLensTotal: 'Precio del armazón + cristales',
        },
      },
      exitTitle: '\'¿SEGURO QUE QUIERES SALIR?\'',
      yourSelections: 'Your selections',
    },
    en_AU: {
      steps: {
        advancedPrescription: {
          manual: {
            card: {
              description: 'This is our quickest and most popular method. We will then recommend the most suitable lens type..',
            },
            whatIsIt: 'What is it?',
            alerts: {
              axisRangeError: 'The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.',
              sphPositiveNegativeWarning: 'You’ve inserted 1 positive and 1 negative value, which is very rare. \nWe suggest double checking your prescription. If this is correct, you can proceed.',
              agreementText: 'By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.',
            },
            continueModal: {
              title: 'Send prescription later',
              message: 'By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.',
            },
          },
          later: {
            card: {
              description: 'We\'ll ask for it after you order, either by uploading it or having us call your doctor.',
            },
          },
        },
        treatmentsFamily: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        thickness: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        transitionColor: {
          indoor: 'Indoor',
          outdoor: 'Outdoor',
        },
        color: {
          confirmedTitle: 'Colour:',
          title: 'Choose your lens colour',
          polarized: 'Polarised only',
          applyAndContinue: 'Apply',
          subtitle: 'Enhanced colour and contrast so you can see more detail',
          tooltip: {
            'switch': 'Reduces glare in extra bright light, for superior clarity and eye protection.',
            polarized: 'Reduces glare in extra bright light, for superior clarity and eye protection.',
          },
        },
        addOns: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.',
        },
        review: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology.',
          paymentInstallments: {
            modal: {
              title: 'Paying in installments',
              subtitle: 'Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.',
            },
          },
        },
      },
    },
    es_MX: {
      steps: {
        type: {
          title: "Selecciona tus necesidades de visión",
          confirmedTitle: "Uso"
        },
        brand: {
          confirmedTitle: "Lens brand:"
        },
        thickness: {
          title: "Selecciona el grosor de tus cristales",
          designTypeTitle: "Selecciona el diseño de la mica"
        },
        gvpTreatment: {
          title: "Choose your Great Value Package"
        },
        treatments: {
          addToBag: "Agregar a la cesta",
          subtitle: "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones."
        },
        color: {
          confirmedTitle: "Color:",
          tooltip: {
            antiReflective: "Reduce los reflejos y proporciona un escudo invisible. Resiste el resplandor, el polvo y las manchas para disfrutar de una visión más clara en todos los sentidos.",
            uvProtection: "Capas protectoras duras en la parte delantera y trasera de las lentes para disfrutar de la máxima protección contra los rayos UV y los arañazos.",
            polarized: "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            switch: "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            antiScratch: "Protege la superficie de las lentes de los impactos fuertes "
          }
        },
        review: {
          title: "Revisa tus selecciones",
          subtitle: "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase.",
          paymentInstallments: {
            installmentsLabel: "Paga en cuotas sin intereses con\n###PAYMENT_TYPE###",
            modal: {
              title: "Pago a plazos",
              subtitle: "¿Quieres separar el costo de tu pedido con ###BRAND###? \n Selecciona ###PAYMENT_TYPE### como tu método de pago al finalizar la compra \n para pagar en cuotas sin intereses, sin cargos ocultos.",
              affirm: "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. ###PAYMENT_TYPE### ofrece las opciones de pago, están sujetas a una verificación de elegibilidad y es posible que no esté disponible en todos los estados. Residentes de California: Los préstamos de ###PAYMENT_TYPE### Loan Services, LLC se otorgan de conformidad con una licencia de prestamista de California.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay: "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. Debes ser mayor de 18 años, residente de los EE. UU. y cumplir con los criterios de elegibilidad adicionales para calificar. Se pueden aplicar cargos por pagos atrasados. Haz clic aquí para conocer los términos completos. Los préstamos otorgados a los residentes de California se rigen por una licencia de la Ley de prestamistas de California."
            }
          },
          tooltip: "Asegura tus lentes con una garantía extendida para reparaciones y reemplazos ilimitados en cualquier tienda LensCrafters."
        },
        advancedPrescription: {
          manual: {
            title: "Ingresa tu receta",
            card: {
              title: "Ingrésala de manera manual",
              description: "Este es nuestro método más rápido y popular. A continuación, recomendaremos el tipo de mica más adecuado."
            },
            subtitle: "Agrega tus valores de la receta y te recomendaremos las mejores micas para tus necesidades de visión.",
            commentsTitle: "Comments",
            alerts: {
              sphPositiveNegativeWarning: "Insertaste 1 valor positivo y 1 negativo, lo cual es muy poco común. \nTe sugerimos revisar tu receta. Si es correcto, puedes continuar.",
              agreementText: "Al hacer clic en esta casilla, confirmo que los valores de la receta ingresados anteriormente se toman de una receta válida (no vencida) que me fue emitida, firmada por un optometrista u oftalmólogo con licencia."
            },
            continueModal: {
              title: "Envía la receta más tarde",
              message: "Al hacer clic en continuar, te saltarás el paso de receta. La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
              cancel: "No, regresar"
            }
          },
          upload: {
            title: "Tu receta",
            subtitle: "Antes de enviar tu receta, verifica la fecha de vencimiento para asegurarte de que aún sea válida.",
            card: {
              title: "Cárgala ahora"
            },
            fileTooBigErrorTitle: "EL ARCHIVO ES DEMASIADO PESADO",
            fileTooBigErrorDescription: "Tu archivo es demasiado pesado. Considera ###FILE_SIZE### máximo",
            uploadDifferentFile: "SUBIR UN ARCHIVO DISTINTO",
            changeMethod: "Cambiar el método de envío",
            prescriptionUploaded: "RECETA CARGADA"
          },
          later: {
            card: {
              description: "La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico."
            }
          }
        },
        treatmentsFamily: {
          subtitle: "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
          clear: {
            description: "Micas tradicionales y translúcidas, perfectas para el uso diario"
          }
        },
        addOns: {
          confirmedTitle: "Completa tus micas",
          title: "Completa tus micas",
          subtitle: "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones."
        }
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Precio del armazón + cristales"
        }
      },
      noTreatment: "No treatment",
      exitTitle: "'¿SEGURO QUE QUIERES SALIR?'"
    },
    de_DE: {
      steps: {
        review: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert.",
          paymentInstallments: {
            installmentsLabel: "Ratenzahlung in zinslosen Raten mit ###PAYMENT_TYPE###",
            modal: {
              title: "Bezahlung in Raten",
              subtitle: "Sie möchten Ihre Bestellung bei ###BRAND### in Raten zahlen? \\Wählen Sie an der Kasse ###PAYMENT_TYPE### als Zahlungsmethode aus, um in zinslosen Raten zu zahlen, ohne versteckte Gebühren.",
              affirm: "Mit ###PAYMENT_TYPE### können Sie alle zwei Wochen ###INSTALLMENTS### zinslose Raten zahlen, ohne versteckte Gebühren. Die Zahlungsoptionen werden von ###PAYMENT_TYPE### angeboten, unterliegen einer Berechtigungsprüfung und sind möglicherweise nicht in allen Ländern verfügbar. Kalifornien: Kredite von ###PAYMENT_TYPE### Loan Services, LLC werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt.",
              afterpay: "Mit ###PAYMENT_TYPE### können Sie alle zwei Wochen ###INSTALLMENTS### zinslose Raten zahlen, ohne versteckte Gebühren. Sie müssen über 18 Jahre alt sein, einen Wohnsitz in den USA haben und weitere Kriterien erfüllen, um die Ratenzahlung Anspruch nehmen zu können. Es können Säumnisgebühren anfallen. Klicken Sie hier, um die vollständigen Bedingungen einzusehen. Kredite an in Kalifornien ansässige Personen werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt."
            }
          },
          tooltip: "Schützen Sie Ihre Brille mit einer erweiterten Garantie für unbegrenzte Reparatur- und Ersatzleistungen in jedem LensCrafters-Geschäft."
        },
        treatments: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz."
        },
        thickness: {
          designTypeTitle: "Brillenglasdesign auswählen"
        },
        advancedPrescription: {
          manual: {
            title: "Daten aus dem Brillenpass eingeben",
            card: {
              description: "Dies ist unsere schnellste und beliebteste Methode. Wir empfehlen Ihnen dann den am besten geeigneten Brillenglastyp."
            },
            subtitle: "Fügen Sie Ihre Korrektionswerte hinzu und wir empfehlen Ihnen das Glas, das am besten für Ihre Sehanforderungen geeignet ist.",
            add: "ADD",
            iHaveTwoPd: "Ich habe 2 PD-Nummern",
            commentsTitle: "Comments",
            alerts: {
              sphPositiveNegativeWarning: "Sie haben 1 positiven und 1 negativen Wert eingegeben, was sehr selten ist. \nBitte überprüfen Sie Ihre Eingabe. Falls Ihre Eingabe richtig ist, fahren Sie fort.",
              missingValues: "Bitte geben Sie die noch fehlenden Daten ein",
              missingBaseDir: "Bitte geben Sie die Basisrichtung Ihres Prismas an"
            },
            continueModal: {
              title: "Brillenpass später senden",
              message: "Wenn Sie auf „Weiter“ klicken, überspringen Sie den Schritt „Eingabe der Korrekturwerte“. Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
              cancel: "Nein, zurück"
            }
          },
          upload: {
            title: "Ihre Brillenverordnung",
            subtitle: "Bevor Sie Ihre Brillenverordnung einreichen, überprüfen Sie bitte das Ablaufdatum, um sicherzustellen, dass sie noch gültig ist.",
            prescriptionUploaded: "Brillenverodnung wurde hochgeladen",
            fileTooBigErrorTitle: "Datei ist zu groß",
            fileTooBigErrorDescription: "Ihre Datei ist zu groß. Maximaler Upload ###FILE_SIZE### MB",
            changeMethod: "Übermittlungsart ändern",
            uploadDifferentFile: "Eine andere Datei hochladen",
            pdForm: {
              pupillaryDistance: "PD (Pupillendistanz)",
              whatIsIt: "Was ist das?",
              iHaveTwoPd: "Ich habe 2 PD-Nummern"
            },
            confirmAndContinue: "Bestätigen & Fortfahren"
          },
          later: {
            card: {
              description: "Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern."
            }
          }
        },
        color: {
          tooltip: {
            antiScratch: "Schützt Ihre Glasoberfläche vor harten Stößen "
          }
        },
        treatmentsFamily: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz."
        },
        addOns: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz."
        }
      },
      frameSize: "Rahmengröße"
    },
    de_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            title: 'Daten aus dem Brillenpass eingeben',
            card: {
              description: 'Dies ist unsere schnellste und beliebteste Methode. Wir empfehlen Ihnen dann den am besten geeigneten Brillenglastyp.',
            },
            subtitle: 'Fügen Sie Ihre Korrektionswerte hinzu und wir empfehlen Ihnen das Glas, das am besten für Ihre Sehanforderungen geeignet ist.',
            add: 'ADD',
            iHaveTwoPd: 'Ich habe 2 PD-Nummern',
            commentsTitle: 'Comments',
            alerts: {
              sphPositiveNegativeWarning: 'Sie haben 1 positiven und 1 negativen Wert eingegeben, was sehr selten ist. \nBitte überprüfen Sie Ihre Eingabe. Falls Ihre Eingabe richtig ist, fahren Sie fort.',
            },
            continueModal: {
              title: 'Brillenpass später senden',
              message: 'Wenn Sie auf „Weiter“ klicken, überspringen Sie den Schritt „Eingabe der Korrekturwerte“. Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.',
              cancel: 'Nein, zurück',
            },
          },
          upload: {
            title: 'Ihre Brillenverordnung',
            subtitle: 'Bevor Sie Ihre Brillenverordnung einreichen, überprüfen Sie bitte das Ablaufdatum, um sicherzustellen, dass sie noch gültig ist.',
            prescriptionUploaded: 'Brillenverodnung wurde hochgeladen',
            fileTooBigErrorTitle: 'Datei ist zu groß',
            fileTooBigErrorDescription: 'Ihre Datei ist zu groß. Maximaler Upload ###FILE_SIZE### MB',
            changeMethod: 'Übermittlungsart ändern',
            uploadDifferentFile: 'Eine andere Datei hochladen',
            pdForm: {
              pupillaryDistance: 'PD (Pupillendistanz)',
              whatIsIt: 'Was ist das?',
              iHaveTwoPd: 'Ich habe 2 PD-Nummern',
            },
            confirmAndContinue: 'Bestätigen & Fortfahren',
          },
          later: {
            card: {
              description: 'Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.',
            },
          },
        },
        treatmentsFamily: {
          subtitle: 'Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.',
        },
        thickness: {
          designTypeTitle: 'Brillenglasdesign auswählen',
        },
        treatments: {
          subtitle: 'Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.',
        },
        color: {
          tooltip: {
            uvProtection: 'Harte Schutzschichten auf Vorder- und Rückseite der Gläser für maximalen Schutz vor UV-Strahlung und Kratzern.',
            antiReflective: 'Reduzieren Spiegelungen und dienen als unsichtbarer Schutz. Sie sind blendfrei, staub- und schmutzabweisend und sorgen für klare Sicht.',
            antiScratch: 'Schützt Ihre Glasoberfläche vor harten Stößen ',
          },
        },
        addOns: {
          subtitle: 'Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.',
        },
        review: {
          subtitle: 'Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert.',
          paymentInstallments: {
            installmentsLabel: 'Ratenzahlung in zinslosen Raten mit ###PAYMENT_TYPE###',
            modal: {
              title: 'Bezahlung in Raten',
              subtitle: 'Sie möchten Ihre Bestellung bei ###BRAND### in Raten zahlen? \\Wählen Sie an der Kasse ###PAYMENT_TYPE### als Zahlungsmethode aus, um in zinslosen Raten zu zahlen, ohne versteckte Gebühren.',
              affirm: 'Mit ###PAYMENT_TYPE### können Sie alle zwei Wochen ###INSTALLMENTS### zinslose Raten zahlen, ohne versteckte Gebühren. Die Zahlungsoptionen werden von ###PAYMENT_TYPE### angeboten, unterliegen einer Berechtigungsprüfung und sind möglicherweise nicht in allen Ländern verfügbar. Kalifornien: Kredite von ###PAYMENT_TYPE### Loan Services, LLC werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt.',
              afterpay: 'Mit ###PAYMENT_TYPE### können Sie alle zwei Wochen ###INSTALLMENTS### zinslose Raten zahlen, ohne versteckte Gebühren. Sie müssen über 18 Jahre alt sein, einen Wohnsitz in den USA haben und weitere Kriterien erfüllen, um die Ratenzahlung Anspruch nehmen zu können. Es können Säumnisgebühren anfallen. Klicken Sie hier, um die vollständigen Bedingungen einzusehen. Kredite an in Kalifornien ansässige Personen werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt.',
            },
          },
          tooltip: 'Schützen Sie Ihre Brille mit einer erweiterten Garantie für unbegrenzte Reparatur- und Ersatzleistungen in jedem LensCrafters-Geschäft.',
        },
      },
      frameSize: 'Rahmengröße',
    },
    it_IT: {
      steps: {
        advancedPrescription: {
          manual: {
            addPrismValues: "Aggiungi valori prismatici",
            commentsTitle: "Comments",
            alerts: {
              sphPositiveNegativeWarning: "Hai inserito un valore positivo e uno negativo, una combinazione molto rara. \nPer sicurezza, controlla la tua prescrizione. Se è tutto corretto, procedi pure."
            }
          },
          doctor: {
            card: {
              description: "Chiederemo la tua prescrizione medica direttamente a lei/lui."
            }
          },
          later: {
            card: {
              description: "Più avanti nel corso dell’evasione del tuo ordine ti verrà richiesto di caricarla o di metterci in contatto con il tuo medico."
            }
          }
        },
        treatmentsFamily: {
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi."
        },
        treatments: {
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi."
        },
        color: {
          tooltip: {
            antiScratch: "Protegge la superficie delle lenti dagli urti "
          }
        },
        addOns: {
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi"
        },
        review: {
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban.",
          paymentInstallments: {
            installmentsLabel: "Paga a rate a tasso zero con ###PAYMENT_TYPE###",
            modal: {
              title: "Pagamento a rate",
              subtitle: "Vuoi diluire il costo del tuo ordine ###BRAND###? \n Scegli ###PAYMENT_TYPE### come metodo di pagamento nel finalizzare l’acquisto \n e pagherai in comode rate a tasso zero, senza alcun costo aggiuntivo.",
              affirm: "Con ###PAYMENT_TYPE### puoi effettuare ###INSTALLMENTS### pagamenti a tasso zero ogni due settimane, senza alcun costo aggiuntivo. Le opzioni di pagamento sono messe a disposizione da ###PAYMENT_TYPE###, sono soggette a verifica di idoneità e potrebbero non essere disponibili in tutti i paesi. Residenti in California: I finanziamenti di ###PAYMENT_TYPE### Loan Services, LLC sono concessi o organizzati in base a una licenza di California Finance Lender.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay: "Con ###PAYMENT_TYPE### puoi effettuare ###INSTALLMENTS### pagamenti a tasso zero ogni due settimane. Solo per residenti degli USA che abbiano già compiuto i 18 anni di età, dietro soddisfazione degli ulteriori requisiti di idoneità. Potrebbero essere applicate penali per ritardo. Clicca qui per leggere tutti i termini. Per i residenti in California i finanziamenti vengono concessi o organizzati in base a una licenza di California Finance Lender."
            }
          },
          tooltip: "Assicura i tuoi occhiali con una estensione di garanzia: comprenderà riparazioni senza alcun limite e sostituzione in qualunque negozio LensCrafters."
        }
      },
      price: {
        totalFrameLens: {
          total: "Totale"
        }
      },
      insuranceSyncedLabel: "Assicurazione applicata",
      frameSize: "Frame size"
    },
    it_CH: {
      steps: {
        color: {
          tooltip: {
            antiReflective: 'Riduce i riflessi e offre uno stato protettivo invisibile.  Contrasta l\'abbagliamento, la polvere e le macchie, garantendo una visione più chiara.',
            uvProtection: 'Strati protettivi rigidi sulla parte anteriore e posteriore delle lenti per un\'elevata protezione dai raggi UV e una maggiore resistenza ai graffi.',
          },
        },
      },
    },
    fr_BE: {
      steps: {
        prescription: {
          title: 'Complétez votre ordonnance\'',
        },
        type: {
          title: 'Sélectionnez vos exigences de vue\'',
          confirmedTitle: 'Utilisez\'',
        },
        brand: {
          title: 'Choisissez la marque de vos verres\'',
        },
        thickness: {
          title: 'Sélectionnez l’épaisseur de vos verres\'',
        },
      },
    },
    fr_FR: {
      steps: {
        prescription: {
          title: "Complétez votre ordonnance'"
        },
        advancedPrescription: {
          manual: {
            title: "Saisissez votre correction",
            card: {
              description: "Il s'agit de notre méthode la plus rapide et la plus populaire. Nous vous recommanderons ensuite le type de verres le plus adapté."
            },
            subtitle: "Ajoutez les valeurs de votre ordonnance et nous vous recommanderons les verres les mieux adaptés à votre vue.",
            commentsTitle: "Comments",
            alerts: {
              sphPositiveNegativeWarning: "Vous avez saisi 1 valeur positive et 1 valeur négative, ce qui est très rare. \nNous vous conseillons de vérifier votre ordonnance. Si ces valeurs sont correctes, veuillez continuer."
            },
            continueModal: {
              message: "En cliquant sur continuer, vous sauterez l'étape de l'ordonnance. Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
              cancel: "Non, retourner en arrière"
            }
          },
          upload: {
            subtitle: "Avant d’envoyer votre ordonnance, vérifier la date d'expiration pour vous assurer qu'elle est toujours valable."
          },
          later: {
            card: {
              description: "Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin."
            }
          }
        },
        brand: {
          confirmedTitle: "Marque'"
        },
        treatmentsFamily: {
          subtitle: "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse."
        },
        thickness: {
          confirmedTitle: "Épaisseur'",
          title: "Sélectionnez l’épaisseur de vos verres'"
        },
        treatments: {
          subtitle: "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse."
        },
        color: {
          tooltip: {
            antiScratch: "Protège la surface de vos verres contre les chocs violents "
          }
        },
        addOns: {
          subtitle: "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse"
        },
        review: {
          subtitle: "Vos lunettes seront équipées de la meilleure technologie Ray-Ban.",
          paymentInstallments: {
            installmentsLabel: "Payez en plusieurs versements sans intérêt avec ###PAYMENT_TYPE###",
            modal: {
              title: "Payer en plusieurs versements",
              subtitle: "Vous souhaitez échelonner le paiement votre commande avec ###BRAND### ? \\Sélectionnez ###PAYMENT_TYPE### comme mode de paiement au moment de payer afin de régler le montant en plusieurs fois sans intérêts et sans frais cachés.",
              affirm: "Avec ###PAYMENT_TYPE###, payez en ###INSTALLMENTS### versements sans intérêts toutes les deux semaines, sans frais cachés. Les options de paiement sont proposées par ###PAYMENT_TYPE###, sont soumises à une vérification d'admissibilité et peuvent ne pas être disponibles dans tous les États. Résidents de Californie : Les prêts proposés par ###PAYMENT_TYPE### Loan Services, LLC sont accordés ou organisés conformément à une licence de prêteur de la Californie.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay: "Avec ###PAYMENT_TYPE###, payez en ###INSTALLMENTS### versements sans intérêts toutes les deux semaines, sans frais cachés. Vous devez avoir plus de 18 ans, être résident aux États-Unis et répondre à d'autres critères d'admissibilité pour être admissible. Des frais de retard peuvent s'appliquer. Cliquez ici pour consulter toutes les conditions. Les prêts aux résidents de la Californie sont accordés ou organisés conformément à une licence prévue par loi sur les prêteurs de la Californie (California Finance Lenders Law)"
            }
          },
          insuranceButtonLabel: "Appliquer l’assurance'",
          tooltip: "Assurez vos lunettes avec une garantie étendue pour une réparation et un remplacement sans limitations dans n'importe quel magasin LensCrafters."
        }
      },
      prescription: {
        toggleLabel: "Ajoutez les valeurs de votre ordonnance, nous vous aiderons à trouver la paire parfaite pour vos besoins'",
        prescriptionTitle: "Ajoutez les valeurs de votre ordonnance, nous vous montrerons le verre répondant parfaitement à vos besoins.'",
        readPrescription: "Comment lire votre ordonnance'",
        addLabel: "J’ai aussi une valeur d’addition'",
        applyButton: "Appliquer et continuer'",
        submitButton: "Envoyer'",
        clearButton: "Effacer tout'",
        skipButton: "Ignorer pour l’instant'",
        compatibleFramesButton: "Acheter des montures compatibles'",
        doctorDetails: "Nous vous demanderons une copie de votre ordonnance ou les coordonnées de votre médecin pendant ou après le paiement'",
        missingValueError: "Ajoutez vos valeurs pour continuer'",
        positiveNegativeError: "Vous avez entré une valeur positive et une valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance.'",
        compatibleFrameError: "Désolés ! La monture que vous avez choisie n’est pas compatible avec les valeurs de votre correction.'",
        axisError: "'Les valeurs d’AXE que vous avez saisies sont incorrectes !'",
        axisSubError: "Ces valeurs doivent entre 0 et 180.'",
        axisSubErrorZero: "Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.'",
        editLabel: "Modifier la correction'",
        twoPDNumbers: "J’ai deux valeurs d'EP'",
        missingInputMessage: "Ajoutez les valeurs manquantes pour compléter le téléchargement de votre ordonnance et nous vous recommanderons le verre le plus adapté.'",
        defaultInputMessage: "Ajoutez vos valeurs SPH et CYL et nous vous recommanderons le verre le plus adapté aux besoins de votre vue.'",
        skipMessage: "Nous vous demanderons de compléter ou de télécharger votre ordonnance plus tard'",
        readyMessage: "Nous sommes maintenant en mesure de vous proposer le verre qui vous convient le mieux  !'",
        bestPrescription: "Le meilleur pour votre correction'",
        proceedCheckbox: "Obligatoire pour continuer'",
        proceedCheckboxError: "Ou {0} paiements de {1}{2} avec'"
      },
      price: {
        startingAt: "Prix partant de'"
      },
      learnMoreMade: "Voir comment c’est fait'",
      learnMoreTreatment: "Voir le traitement'",
      exitTitle: "ÊTES-VOUS SÛR DE VOULOIR SORTIR ?'",
      exitSubtitle: "Votre choix de verres ne sera pas enregistré'",
      exitContinueEditing: "Continuer la modification'",
      exitSave: "Enregistrer et continuer le shopping'",
      insuranceButtonLabel: "Ajouter des prestations sociales'",
      insuranceSyncedLabel: "Prestations sociales appliquées'",
      applyingInsurance: "Application des prestations sociales en cours'",
      frameSize: "Frame size"
    },
    fr_CH: {
      steps: {
        brand: {
          confirmedTitle: 'Marque\'',
        },
      },
      insuranceSyncedLabel: 'Prestations sociales appliquées\'',
    },
    nl_NL: {
      steps: {
        prescription: {
          title: "Rond jouw brilrecept af'"
        },
        advancedPrescription: {
          manual: {
            title: "Voer je brilrecept in",
            card: {
              title: "Voer het met de hand in",
              description: "Dit is de snelste en meest gebruikte methode. Daarna adviseren we je het meest geschikte type brillenglas."
            },
            subtitle: "Voeg je brilrecept toe en we zullen je het brillenglas aanbevelen dat het meest geschikt is voor jouw sterkte.",
            commentsTitle: "Comments",
            alerts: {
              sphPositiveNegativeWarning: "Je hebt 1 positieve en 1 negatieve waarde ingevoerd, dat is erg bijzonder. \nIk adviseer je om het brilrecept te controleren. Als dit juist is, kun je doorgaan."
            },
            continueModal: {
              title: "Stuur het brilrecept later op",
              message: "Door op verder te klikken, sla je de stap betreffende het brilrecept over. We zullen je na de bestelling om het brilrecept vragen dat je vervolgens kunt uploaden, of dat we telefonisch direct bij jouw arts kunnen opvragen.",
              cancel: "Nee, terug"
            }
          },
          upload: {
            subtitle: "Controleer de geldigheidsdatum om na te gaan of je brilrecept nog altijd geldig is, voordat je het indient.",
            card: {
              title: "Upload het nu"
            }
          },
          later: {
            card: {
              title: "Stuur het later op",
              description: "We zullen je na de bestelling om het brilrecept vragen dat je vervolgens kunt uploaden, of dat we telefonisch direct bij jouw arts kunnen opvragen."
            }
          }
        },
        type: {
          title: "Selecteer de benodigde sterkte'",
          confirmedTitle: "Gebruik'"
        },
        brand: {
          title: "Kies het merk brillenglazen'",
          confirmedTitle: "Merk'"
        },
        treatmentsFamily: {
          subtitle: "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming."
        },
        gvpTreatment: {
          title: "Rond jouw voordeelpakket af'"
        },
        thickness: {
          confirmedTitle: "Dikte'",
          title: "Selecteer de dikte van de brillenglazen'"
        },
        treatments: {
          addToBag: "Aan boodschappenmand toevoegen'",
          title: "Rond jouw brillenglas af'",
          subtitle: "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming.",
          confirmedTitle: "Behandelingen'",
          extraTreatments: {
            antiScratch: {
              title: "Krasbestendig'"
            },
            antiReflective: {
              value: "Antireflexbehandeling'"
            },
            uvProtection: {
              title: "UV-bescherming en krasbestendig'"
            }
          }
        },
        color: {
          tooltip: {
            uvProtection: "Harde beschermende coatings aan de voor- en achterkant van de glazen voor maximale uv- en krasbescherming.",
            antiReflective: "Vermindert reflecties en zorgt voor een onzichtbaar schild. De coatings zijn bestand tegen verblinding, stof en vlekken, voor een helderder zicht.",
            antiScratch: "Beschermt je brillenglazen tegen harde schokken "
          }
        },
        addOns: {
          subtitle: "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming"
        },
        review: {
          subtitle: "Jouw bril wordt geleverd met de beste technologie van Ray-Ban.",
          paymentInstallments: {
            installmentsLabel: "Gespreide betaling met rentevrije afbetalingstermijnen met ###PAYMENT_TYPE###",
            modal: {
              title: "Betaling met afbetalingstermijnen",
              subtitle: "Wil je de kosten van jouw bestelling spreiden met ###BRAND###? \n Selecteer tijdens het afrekenen ###PAYMENT_TYPE### als betaalwijze \n om te betalen met rentevrije afbetalingstermijnen, zonder verborgen kosten.",
              affirm: "Met ###PAYMENT_TYPE###, kun je afbetalen met ###INSTALLMENTS### rentevrije betalingen die elke twee weken worden afgeschreven, zonder verborgen kosten. De betaalmogelijkheden worden aangeboden door ###PAYMENT_TYPE###. Ze worden onderworpen aan een toetsing van de acceptatiecriteria en de beschikbaarheid verschilt per land. Inwoners van Californië: Leningen die worden verstrekt door ###PAYMENT_TYPE### Loan Services, LLC vinden plaats of worden geregeld in overeenkomst met een Californische licentie voor kredietgevers.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay: "Met ###PAYMENT_TYPE###, kun je afbetalen met ###INSTALLMENTS### rentevrije betalingen die elke twee weken worden afgeschreven. Je moet 18 jaar of ouder zijn, in de VS wonen en aan extra acceptatiecriteria voldoen om hier aanspraak op te kunnen maken. Er kunnen kosten aan verbonden zijn. Klik hier voor alle voorwaarden. Leningen die verstrekt worden aan inwoners in Californië vinden plaats of worden geregeld in overeenkomst met een Californische licentie voor kredietgevers."
            }
          },
          tooltip: "Verzeker je bril met een verlengde garantie voor onbeperkte reparatie en vervanging in elke LensCrafters-winkel."
        }
      },
      prescription: {
        toggleLabel: "Voeg de gegevens van jouw brilrecept in, we helpen je het juiste brillenglas voor jouw sterkte te vinden.'",
        prescriptionTitle: "Voeg de gegevens van jouw brilrecept in, we tonen je het juiste brillenglas voor jouw sterkte.'",
        readPrescription: "Hoe lees je het brilrecept'",
        addLabel: "Ik heb ook een ADD-waarde'",
        applyButton: "Toepassen en doorgaan'",
        submitButton: "Verzenden'",
        clearButton: "Alle wissen'",
        skipButton: "Tijdelijk overslaan'",
        compatibleFramesButton: "Koop compatibele monturen'",
        doctorDetails: "Na het afrekenen vragen we je om een kopie van jouw brilrecept of de gegevens van jouw oogarts'",
        missingValueError: "Voeg jouw gegevens toe om door te gaan'",
        positiveNegativeError: "Je hebt één positieve waarde en één negatieve waarde ingevoerd. Dit is bijzonder zeldzaam. Ik adviseer je om het brilrecept te controleren.'",
        compatibleFrameError: "'Helaas! Het montuur dat je hebt gekozen is niet geschikt voor jouw brilrecept.'",
        compatibleFrameSubLabel: "Geen probleem. We hebben monturen die voor jouw brilrecept geschikt zijn.'",
        axisError: "'De AS-waarden die je hebt ingevoerd zijn niet juist!'",
        axisSubError: "Deze moeten tussen 0 en 180 liggen.'",
        axisSubErrorZero: "Als de CYL-waarde hoger of lager is dan 0, dan kunnen de AS-waarden niet 0 zijn.'",
        editLabel: "Brilrecept bewerken'",
        pdHelp: "Wat is dit?'",
        twoPDNumbers: "Ik heb twee PD-nummers'",
        missingInputMessage: "Voeg de ontbrekende gegevens in zodat je het uploaden van het brilrecept kunt afronden en we zullen je het meest geschikte brillenglas aanbevelen.'",
        defaultInputMessage: "Voeg jouw SPH- en CYL-waarden in en we zullen je het brillenglas aanbevelen dat het meest geschikt is voor jouw sterkte.'",
        skipMessage: "We vragen later om het brilrecept af te ronden of te uploaden'",
        readyMessage: "Nu kunnen we je het beste brillenglas aanbevelen!'",
        bestPrescription: "Het beste voor jouw brilrecept'",
        proceedCheckbox: "Vereist om door te gaan'",
        proceedCheckboxError: "Of {0} betalingen van {1}{2} met'"
      },
      price: {
        startingAt: "Vanaf'"
      },
      learnMoreMade: "Zie hoe hij wordt gemaakt'",
      learnMoreTreatment: "Bekijk de behandeling'",
      exitTitle: "WEET JE HET ZEKER DAT JE WILT AFSLUITEN?'",
      exitSubtitle: "De gekozen brillenglazen worden niet opgeslagen'",
      exitContinueEditing: "Doorgaan met bewerken'",
      exitSave: "Opslaan en verder winkelen'",
      insuranceButtonLabel: "Verzekeringsuitkeringen toevoegen'",
      insuranceSyncedLabel: "Verzekeringsuitkeringen toegepast'",
      applyingInsurance: "Verzekeringsuitkeringen worden toegepast'",
      frameSize: "Frame size"
    },
    nl_BE: {},
    no_NO: {
      steps: {
        advancedPrescription: {
          manual: {
            card: {
              description: "Dette er vår raskeste og mest populære metode. Vi vil da anbefale det brilleglasset som passer best."
            },
            pupillaryDistanceSubtitle: "Du kan velge standardinnstillingene <b>61 for kvinner</b> og <b>64 for menn</b> hvis du bruker en gjennomsnittlig eller liten styrke. Hvis du bruker stor styrke (eller vil vite nøyaktig pupillavstand), må du <a  data-element-id=\"X_X_LensPanel_AdvancedPrescription-MeasurePD\">måle pupillavstanden din</a>",
            continueModal: {
              cancel: "Nei, gå tilbake"
            },
            digitalOptometry: {
              appSubtitle: "Pupillavstanden er avstanden i millimeter (mm) fra midten av en pupill til midten av den andre. Den angir nøyaktig hvilken del av brilleglasset du ser gjennom, og sikrer optimal komfort og klarhet. Du kan måle med appen eller manuelt."
            }
          },
          upload: {
            prescriptionUploaded: "Brilleseddel lastet opp"
          },
          later: {
            card: {
              description: "Vi vil be om det etter bestillingen din, enten ved å laste den opp eller ved at vi ringer legen din."
            }
          }
        },
        treatmentsFamily: {
          subtitle: "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig og UV-beskyttelse er inkludert."
        },
        treatments: {
          subtitle: "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig behandling og UV-beskyttelse er inkludert."
        },
        color: {
          tooltip: {
            uvProtection: "Harde beskyttende lag foran og bak på brilleglassene for maksimal UV- og ripebeskyttelse."
          }
        },
        addOns: {
          subtitle: "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig og UV-beskyttelse er inkludert."
        },
        review: {
          subtitle: "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi."
        }
      },
      frameSize: "Innfatningsstørelse"
    },
    sv_SE: {
      steps: {
        prescription: {
          title: "Slutför ditt glasögonrecept'"
        },
        advancedPrescription: {
          manual: {
            commentsTitle: "Comments",
            alerts: {
              sphPositiveNegativeWarning: "Du angav ett positivt och ett negativ värde vilket är mycket sällsynt. \nVi rekommenderar att du dubbelkollar ditt glasögonrecept. Om det är rätt kan du fortsätta."
            }
          }
        },
        type: {
          title: "Välj dina synbehov'"
        },
        brand: {
          title: "Välj ditt linsmärke'",
          confirmedTitle: "Varumärke'"
        },
        treatmentsFamily: {
          subtitle: "Dina glasögon har förstklassig Ray-Ban-teknik. Reptålighet och UV-skydd ingår."
        },
        gvpTreatment: {
          title: "Slutför ditt Prisvärda paket'"
        },
        thickness: {
          confirmedTitle: "Tjocklek'",
          title: "Välj din linstjocklek'"
        },
        treatments: {
          addToBag: "Lägg till i varukorg'",
          title: "Slutför din lins'",
          subtitle: "Dina glasögon har förstklassig Ray-Ban-teknik. Reptålighet och UV-skydd ingår.",
          confirmedTitle: "Behandlingar'",
          extraTreatments: {
            antiScratch: {
              title: "Reptålighet'"
            },
            antiReflective: {
              value: "Antireflektionsbehandling'"
            },
            uvProtection: {
              title: "UV-skydd och reptålighet'"
            }
          }
        },
        color: {
          tooltip: {
            antiScratch: "Skyddar ytan på din lins mot kraftig yttre påverkan. "
          }
        },
        addOns: {
          subtitle: "Dina glasögon har förstklassig Ray-Ban-teknik. Reptålighet och UV-skydd ingår"
        },
        review: {
          subtitle: "Dina glasögon har förstklassig Ray-Ban-teknik.",
          paymentInstallments: {
            installmentsLabel: "Betala över tid genom räntefria avbetalningar med ###PAYMENT_TYPE###",
            modal: {
              title: "Betalning genom avbetalningar",
              subtitle: "Vill du sprida ut kostnaden för din beställning med ###BRAND###? \n Välj ###PAYMENT_TYPE### som ditt betalningssätt vid utcheckningen \n för att betala genom räntefria avbetalningar, utan dolda avgifter.",
              affirm: "Gör tre räntefria betalningar med ###PAYMENT_TYPE### varannan vecka utan några dolda avgifter. Betalningsalternativ erbjuds av ###PAYMENT_TYPE###, genomgår en behörighetskontroll och kanske inte är tillgängliga i alla delstater. Invånare i Kalifornien: Lån via lånetjänsten LCC hos ###PAYMENT_TYPE### genomförs eller utformas i enlighet med en licens från en långivare i Kalifornien.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay: "Gör fyra räntefria betalningar med ###PAYMENT_TYPE### varannan vecka. Du måste ha fyllt 18 år, vara bosatt i USA och uppfylla ytterligare behörighetskriterier för att kvalificera dig. Förseningsavgifter kan tas ut. Klicka här för fullständiga villkor. Lån till invånare i Kalifornien genomförs eller utformas i enlighet med en licens enligt Kaliforniens finansieringslag."
            }
          },
          insuranceButtonLabel: "Applicera försäkring'",
          tooltip: "Försäkra dina glasögon med en utökad garanti för obegränsad reparation och utbyte hos valfri LensCrafters-butik."
        }
      },
      prescription: {
        toggleLabel: "Lägg till dina glasögonreceptvärden, så hjälper vi dig att hitta den perfekta linsen för dina synbehov.'",
        prescriptionTitle: "Lägg till dina glasögonreceptvärden, så visar vi dig den perfekta linsen för dina synbehov.'",
        readPrescription: "Hur du läser ditt glasögonrecept'",
        addLabel: "Jag har även ett ADD-värde'",
        applyButton: "Applicera och fortsätt'",
        submitButton: "Skicka'",
        clearButton: "Rensa alla'",
        skipButton: "Hoppa över tills vidare'",
        compatibleFramesButton: "Köp kompatibla bågar'",
        doctorDetails: "Vi kommer att be om en kopia på ditt glasögonrecept eller kontaktuppgifterna till din optiker under eller efter utcheckningen.'",
        missingValueError: "Lägg till dina värden för att fortsätta'",
        positiveNegativeError: "Du skrev in ett positivt värde och ett negativt värde, vilket är ganska sällsynt. Vi rekommenderar att du dubbelkollar ditt glasögonrecept.'",
        compatibleFrameError: "Tyvärr! Bågen som du har valt fungerar inte med dina glasögonreceptvärden.'",
        compatibleFrameSubLabel: "Inga problem, vi har bågar som passar ditt glasögonrecept.'",
        axisError: "AXIS-värdena som du har angett är inte korrekta!'",
        axisSubError: "Dessa värden måste vara mellan 0 och 180.'",
        axisSubErrorZero: "Om CYL-värdet är högre eller lägre än 0, kan AXIS-värdena inte vara 0.'",
        editLabel: "Redigera glasögonrecept'",
        pdHelp: "Vad är detta?'",
        twoPDNumbers: "Jag har två PD-nummer.'",
        missingInputMessage: "Lägg till de av dina värden som saknas för att slutföra uppladdningen av ditt glasögonrecept så kommer vi att rekommendera den lins som är mest lämplig.'",
        defaultInputMessage: "Lägg till dina SPH- och CYL-värden så kommer vi att rekommendera den lins som är mest lämplig för dina synbehov.'",
        skipMessage: "Vi kommer att be dig slutföra eller ladda upp ditt glasögonrecept senare.'",
        readyMessage: "Vi kan inte föreslå den rätta linsen för dig!'",
        bestPrescription: "Bäst för ditt glasögonrecept'",
        proceedCheckbox: "Du måste fortsätta'",
        proceedCheckboxError: "Eller {0} betalningar av {1}{2} med'"
      },
      price: {
        startingAt: "Lägsta pris'"
      },
      learnMoreMade: "Se hur den görs'",
      learnMoreTreatment: "Se behandlingen'",
      insuranceButtonLabel: "Lägg till försäkringsförmåner'",
      insuranceSyncedLabel: "Försäkringsförmåner applicerade'",
      applyingInsurance: "Applicera försäkringsförmåner'",
      frameSize: "Frame size",
      yourSelections: "Dina val"
    },
    ja_JP: {
      steps: {
        advancedPrescription: {
          title: "処方せんをお持ちですか？",
          manual: {
            title: "処方せんを送信",
            card: {
              title: "マニュアル入力",
              description: "最も早く最も人気の方法です。これによって最適なレンズタイプをお勧めできます。"
            },
            subtitle: "処方せん数値を追加し、お客様が必要とする最適なレンズをお勧めします。",
            sphere: "Sphere（スフィア） (SPH)",
            cylinder: "Cylinder（シリンダー） (CYL)",
            commentsTitle: "Comments",
            alerts: {
              sphPositiveNegativeWarning: "プラス値1つとマイナス値1つを入力されましたが、これは非常に稀なケースです。\n処方せんをもう一度確認するようにお勧めいたします。もしこれが正確な値の場合、次に進んでください。",
              incompatibleWithPrescriptionTitle: "ご入力いただいた度数では製作できません。恐れ入りますが、別のフレームをご検討下さい",
              agreementText: "✓私（注文者）は、上記入力された情報が、医療機関発行の有効な処方箋に基づいた数値であることを確認しました。"
            },
            continueModal: {
              title: "処方せんを後ほど送信する",
              message: "先に進むをクリックすることで、処方せんのステップを飛ばすことができます。ご注文後に書類をアップロードするか、主治医からお電話していただくようにお願いします。",
              continue: "了解して、先に進みます",
              cancel: "元に戻ります"
            },
            manualPreviewTitle: "処方箋の詳細"
          },
          upload: {
            title: "お客様の処方せん",
            subtitle: "処方せんを送信する前に、有効期限内かをもう一度確認してください。",
            card: {
              title: "今すぐアップロードする",
              description: "残りは全て当社にお任せください。ご利用いただけるファイル形式は次の通りです：.pdf、 .png、 .jpeg、 .gif、 .tiff、 .bmp、 .docx （最大10MB）、IWork pages"
            },
            uploadDifferentFile: "別のファイルをアップロードする",
            changeMethod: "送信方法を変更する",
            confirmAndContinue: "確定して進む"
          },
          later: {
            card: {
              title: "後ほど送信",
              description: "ご注文後に書類をアップロードするか、主治医からお電話していただくようにお願いします。"
            }
          }
        },
        type: {
          confirmedTitle: "レンズの補正機能"
        },
        treatmentsFamily: {
          title: "ご自分のレンズタイプを選択",
          subtitle: "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。スクラッチ耐性と紫外線保護が含まれています。"
        },
        thickness: {
          designTypeTitle: "ご自分のレンズデザインを選択"
        },
        treatments: {
          subtitle: "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。スクラッチ耐性と紫外線保護が含まれています。",
          extraTreatments: {
            antiScratch: {
              title: "Scratch resistant"
            },
            uvProtection: {
              title: "UV Protection"
            }
          }
        },
        lensColor: {
          confirmedTitle: "カラー：",
          detailBox: {
            lightTransmission: "光透過",
            lightConditions: "光条件"
          }
        },
        color: {
          confirmedTitle: "カラー：",
          titleMobile: "レンズカラーを選択",
          tooltip: {
            antiScratch: "レンズ表面を強い衝撃から守ります "
          }
        },
        addOns: {
          title: "レンズに追加するには",
          subtitle: "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。スクラッチ耐性と紫外線保護が含まれています"
        },
        review: {
          subtitle: "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。",
          paymentInstallments: {
            installmentsLabel: "###PAYMENT_TYPE###で無利子の分割払い",
            modal: {
              title: "分割払い",
              subtitle: "###BRAND###でご注文を分割してみませんか? \n 会計時の支払い方法に###PAYMENT_TYPE###を選択し \n 手数料なしで無利子の分割払いをご利用ください。",
              affirm: "###PAYMENT_TYPE###なら、手数料ゼロ、無利子の分割で2週間毎に###INSTALLMENTS###回払いにできます。###PAYMENT_TYPE###の支払い方法は、条件確認があり、すべてが承認されるとは限らないことをご了承ください。カリフォルニア居住者：###PAYMENT_TYPE### Loan Services, LLCのローンはCalifornia Finance Lenderライセンスによるかその規定に従います。",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay: "###PAYMENT_TYPE###なら、無利子で2週間毎に###INSTALLMENTS###回払いにできます。18歳以上でアメリカ居住の方が対象で、 承認にはさらに追加の条件があります。遅滞には手数料が課されます。ここをクリックし約定を完了してください。カリフォルニア居住者の方向けのローンは、California Finance Lenders法のライセンスによるかその規定に従います。"
            }
          },
          tooltip: "アイウェアの延長保証は、全てのLensCrafters ストアで無制限の修理や交換を受けられます。"
        }
      },
      prescription: {
        applyButton: "Apply & continue"
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price"
        }
      },
      exitYes: "Yes, exit",
      frameSize: "Frame size"
    },
  },
};
