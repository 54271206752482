import React, { useState, useEffect, useRef, useMemo } from "react";
import "./default.module.scss";
import Image from "../common/Image";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import { StepBadgeType } from "../StepBadge/types/StepBadgeType";
import {
  useTranslate,
  useTranslation,
} from "../../configurator/translations/TranslationHooks";
import {
  getChipsList,
  getCorrectAnalyticsStepContainerName,
} from "../../configurator/ConfigLoader";
import { workflow } from "../../configurator/models/Workflow";
import { AutomaticDiscountParameter } from "../../configurator/models/AutomaticDiscountParameter";
import { workflowActions } from "../../redux/slices/workflow";
import useDelegatingLearnMore from "../../configurator/hooks/useDelegatingLearnMore";
import { isDesktop, isMobile } from "react-device-detect";
import {
  useCurrentPackages,
  useLearnMore,
  usePriceDiscountBadge,
  useLargeIcons,
  useTransitionColors,
} from "@hooks";

export interface StepCardProps {
  title: string;
  description?: string;
  price?: number;
  discount?: number;
  insPrice?: number;
  onClick?: (p: any) => void;
  badge?: StepBadgeType;
  textBadge?: string;
  imageUrl?: string;
  id: string;
  noMarginTitle?: boolean;
  included?: boolean;
  noImageCard?: boolean;
  moreInfo?: Function;
  sequence?: number;
  transitionId?: string;
  disabled?: boolean;
  discountLoading?: boolean;
  contentIdentifier?: string;
  moreInfoTitle?: string;
  ignoreInsurance?: boolean;
  showSavingsLabel?: boolean;
  fadeShadow3DIcon?: boolean;
}

export function StepCard({
  title,
  description,
  price,
  discount,
  insPrice,
  badge,
  textBadge,
  onClick,
  imageUrl,
  id,
  included,
  noImageCard,
  moreInfo,
  sequence,
  transitionId,
  disabled,
  discountLoading,
  contentIdentifier,
  moreInfoTitle,
  ignoreInsurance,
  showSavingsLabel,
  fadeShadow3DIcon,
}: StepCardProps) {
  const translate = useTranslation();
  const currentPackages = useCurrentPackages();
  const currentStep = useSelector(
    (state: any) => state?.workflow?.currentStep?.key
  );
  const { learnMoreVisible, delegateLearnMoreCheck, getMoreInfoTitle } =
    useLearnMore(currentStep, contentIdentifier);
  const { isLargeIconsActive, getIcons } = useLargeIcons();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [apngLoaded, setApngLoaded] = useState(false);
  let promoBadgeID = id;
  let splittedID = promoBadgeID.split("$");
  if (splittedID.length > 1) {
    promoBadgeID = splittedID[1];
  } else {
    promoBadgeID = splittedID[0];
  }
  const promoBadge = usePriceDiscountBadge(promoBadgeID, discount, price);
  const dispatch = useDispatch();
  const configProjectImage = useSelector(
    (state: any) => state.config.baseURLs.projectImage
  );
  const { colors, selectColor, selectedColor } = useTransitionColors(id);

  const stepCardButtonRef = useRef(null);

  useEffect(() => {
    const isFirstCard = sequence && sequence === 1;
    if (selectedColor && isFirstCard) {
      selectColor(selectedColor);
      stepCardButtonRef?.current?.focus();
      stepCardButtonRef?.current?.click();
    }
  }, [selectedColor]);

  const largeIconBasepath = useSelector(
    (state: any) =>
      `${state.config.baseURLs.assetsCDN || ""}/extra/image/rxc/icons/`
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const [chips, setChips] = useState("");
  const onCardClick = () => {
    let isClickable =
      (insuranceEnabled &&
        (price === undefined || price === null || isNaN(price))) ||
      (insPrice !== undefined && insPrice !== null && !isNaN(insPrice));

    if (!insuranceEnabled || isNonPrescriptionAvailable() || isClickable) {
      if (onClick) {
        if (promoBadge) {
          const currentStep = workflow.currentStep;
          const param = new AutomaticDiscountParameter(
            promoBadge.key,
            promoBadge.value,
            {
              key: currentStep.key,
              progressive: currentStep.progressive,
            }
          );
          dispatch(
            workflowActions.setAutomaticDiscountBadgeStepSelection(param)
          );
        }
        onClick(id);
      }
    }
  };

  const translation = useTranslation();

  const getDataElementIdForCard = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-Choose";
  };

  const getDataElementIdForLearnMore = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-LearnMoreOpen";
  };

  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };

  const isBadgeVisible = () => {
    return (
      badge &&
      (!promoBadge ||
        (promoBadge &&
          price !== undefined &&
          price !== null &&
          discount !== undefined &&
          discount !== null))
    );
  };
  const isTopPromoBadgeVisible = () => {
    return (
      promoBadge &&
      (price === undefined || price === null) &&
      (discount === undefined || discount === null)
    );
  };
  const fileNameImages = useMemo(() => {
    return getIcons(transitionId || id);
  }, [id, translation]);

  const onApngLoaded = () => {
    setApngLoaded(true);
  };
  const onPngLoaded = () => {
    document.documentElement.classList.remove("showSkeleton");
  };
  const currentStepKey = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );

  useEffect(() => {
    if (description) {
      let ch = getChipsList(description);
      if (ch) {
        setChips("<ul>" + ch[1]);
      }
    }
  }, [description]);

  return (
    <div
      className={"StepCard__container"}
      onClick={onCardClick}
      data-element-id={getDataElementIdForCard()}
      data-description={title}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <button className={"StepCard__card" + (disabled ? "_disabled" : "")}>
        <div className={"StepCard__layout"}>
          {disabled && <div className="StepCard__layout__disabled" />}
          {!isLargeIconsActive ? (
            imageUrl &&
            !isMobile && (
              <div
                className={`StepCard__layout__imgcontainer ${
                  noImageCard ? "noImage" : ""
                }`}
              >
                <Image
                  imageClass={`StepCard__layout__imgcontainer__img`}
                  url={configProjectImage}
                  fileName={imageUrl}
                  alt={imageUrl}
                />
              </div>
            )
          ) : (
            //Large Icons
            <div
              className={`StepCard__layout__largeimgcontainer ${
                noImageCard ? "noImage" : ""
              } ${fadeShadow3DIcon ? " fade-shadow" : ""}`}
            >
              <>
                <Image
                  imageClass={`StepCard__layout__largeimgcontainer__largeimg`}
                  url={largeIconBasepath}
                  fileName={
                    selectedColor?.imageFileName1 ||
                    fileNameImages?.[0] ||
                    imageUrl
                  }
                  alt={imageUrl}
                  style={{
                    display:
                      isMouseOver || (apngLoaded && isMobile)
                        ? "none"
                        : "inherit",
                  }}
                  onLoad={onPngLoaded}
                />
                {fileNameImages?.length === 2 && (
                  <Image
                    imageClass={`StepCard__layout__largeimgcontainer__largeimg`}
                    url={largeIconBasepath}
                    fileName={
                      selectedColor?.imageFileName2
                        ? `${selectedColor?.imageFileName2}.apng`
                        : fileNameImages[1]
                        ? fileNameImages[1]
                        : imageUrl
                    }
                    alt={imageUrl}
                    style={{
                      display:
                        !apngLoaded || (!isMouseOver && isDesktop)
                          ? "none"
                          : "inherit",
                    }}
                    onLoad={onApngLoaded}
                  />
                )}
              </>
            </div>
          )}

          {isBadgeVisible() && (
            <BrandComponent
              componentName="StepBadge"
              parameter={{ type: badge, text: textBadge }}
            />
          )}
          {isTopPromoBadgeVisible() && (
            <div className="StepCard__layout__topBadgeWrapper">
              <BrandComponent
                componentName="StepPriceBadge"
                parameter={{ badge: promoBadge?.badge }}
              />
            </div>
          )}
          <div
            className={
              !imageUrl
                ? "StepCard__layout__content spacer"
                : "StepCard__layout__content"
            }
          >
            <div
              className={
                badge
                  ? "StepCard__layout__content__title__badgespacer"
                  : "StepCard__layout__content__title"
              }
            >
              {title}
            </div>
            <div className={"StepCard__layout__content__description"}>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            {disabled && isDesktop && (
              <>
                <div className="StepCard__layout__content__spacer"></div>
                <p id="notAvailable">{translation("notAvailable")}</p>
              </>
            )}
            {learnMoreVisible() ? (
              <>
                <div className={"StepCard__layout__content__spacer"} />
                <div className={"StepCard__layout__content__learnMore"}>
                  {
                    //da capire se serve
                  }
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      delegateLearnMoreCheck();
                    }}
                  >
                    {moreInfoTitle
                      ? translation(moreInfoTitle)
                      : getMoreInfoTitle()}
                  </p>
                </div>
                {price != null ? (
                  <div className={"StepCard__layout__content__price"}>
                    {!included ? (
                      <BrandComponent
                        componentName="StepPrice"
                        parameter={{
                          price,
                          discount,
                          insPrice,
                          promo: promoBadge?.badge,
                          ignoreInsurance:
                            isNonPrescriptionAvailable() || ignoreInsurance,
                          discountLoading,
                        }}
                      />
                    ) : (
                      translation("price.included")
                    )}
                  </div>
                ) : null}
              </>
            ) : !moreInfo && (price === undefined || price === null) ? null : (
              <>
                <div className={"StepCard__layout__content__spacer"} />
                <div
                  className={
                    "StepCard__layout__content__learnMorePriceContainer" +
                    (!learnMoreVisible() && !showSavingsLabel
                      ? " onlyPrice"
                      : "")
                  }
                >
                  {learnMoreVisible() && (
                    <a
                      className={"StepCard__layout__content__learnMore"}
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        moreInfo();
                      }}
                      data-element-id={getDataElementIdForLearnMore()}
                    >
                      {moreInfoTitle ? moreInfoTitle : getMoreInfoTitle()}
                    </a>
                  )}
                  {showSavingsLabel && (
                    <BrandComponent
                      componentName="Savings"
                      parameter={{ discount: discount }}
                    ></BrandComponent>
                  )}
                  {price != null ? (
                    <div className={"StepCard__layout__content__price"}>
                      {!included ? (
                        <BrandComponent
                          componentName="StepPrice"
                          parameter={{
                            price,
                            discount,
                            insPrice,
                            promo: promoBadge?.badge,
                            ignoreInsurance: isNonPrescriptionAvailable(),
                            discountLoading,
                          }}
                        />
                      ) : (
                        translation("price.included")
                      )}
                    </div>
                  ) : null}
                </div>
              </>
            )}
            {disabled && isMobile && (
              <>
                <div className="StepCard__layout__content__spacer"></div>
                <p id="notAvailable">{translation("notAvailable")}</p>
              </>
            )}
          </div>
        </div>
      </button>
    </div>
  );
}
