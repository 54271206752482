import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import "./default.module.scss";
import { BrandComponent } from "../common/BrandComponent";
import { workflow } from "../../configurator/models/Workflow";
import { isShowFramePlusLensesPrice } from "../../configurator/ConfigLoader";
import { useShowFramePlusLensesPrice, usePrismPrice } from "@hooks";
import { useSelector } from "react-redux";

interface BottomBarTotalPriceProps {
  total: { price: number; discount: number; insPrice?: number };
}

/**
 * Small component that displays the total price and the gst in the bottom bar. It accepts
 * an input of type ```BottomBarTotalPriceProps``` which is an interface defining one
 * parameter:
 *
 * ```typescript
 * {
 *      total: { price: number, discount: number }
 * }
 * ```
 *
 * The component displays these prices using the ```Price``` component.
 */
export function BottomBarTotalPrice({ total }: BottomBarTotalPriceProps) {
  const priceTotalLabel = useTranslate("price.totalFrameLens.frameLensTotal");
  const priceFrameLabel = useTranslate("price.totalFrameLens.frame");
  const bundleFrameLensLabel = useTranslate("price.bundleFrameLensStartingAt");
  const taxLabel = useTranslate("tax");
  const isShow = isShowFramePlusLensesPrice();
  const showFramePlusLenses = useShowFramePlusLensesPrice();
  const currentStep = useSelector((state: any) => state.workflow?.currentStep?.progressive);
  const lensBundleStep = workflow?.stepChain?.find(s => s.key === "LensBundle")?.progressive;
  const isPrismPriceAdded = useSelector((state: any) => state.prescription?.isPrismPriceAdded);
  const prismPrice = usePrismPrice();

  return (
    <div className={"BottomBarTotalPrice"}>
      <div className={"BottomBarTotalPrice__pricerow"}>
        {isPrismPriceAdded && prismPrice ? (<p> {priceTotalLabel}:</p>) :
          showFramePlusLenses && currentStep < lensBundleStep ? (
            <div className={"BottomBarTotalPrice__pricerow__label"}>
              <p style={{ textTransform: "initial" }}>{bundleFrameLensLabel}</p>
            </div>
          ) : (
            <div className={"BottomBarTotalPrice__pricerow__label"}>
              {!workflow.hasManualSelectionBeenDone() ? (
                isShow ? (
                  <p> {priceFrameLabel}:</p>
                ) : (
                  <></>
                )
              ) : isShow ? (
                <p> {priceTotalLabel}:</p>
              ) : (
                <></>
              )}
            </div>
          )}
        <div className={"BottomBarTotalPrice__pricerow__price"}>
          {isShow ? (
            <BrandComponent
              componentName="StepPrice"
              parameter={{
                price: total.price,
                discount: total.discount,
                priceStyle: {
                  fontSize: 20,
                  fontFamily: "sofia-pro-bold",
                  color: "#222222",
                },
                insPrice:
                  total.insPrice || total.insPrice === 0
                    ? total.insPrice
                    : null,
                hideSign: true,
                ignorePriceLabels: true,
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={"BottomBarTotalPrice__gstrow"}>
        {/* <div className={"BottomBarTotalPrice__gstrow__label"}>{taxLabel}</div> */}
      </div>
    </div>
  );
}
