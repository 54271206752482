import React from "react";
import "./default.module.scss";
import { useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";

export interface UploadTableReadOnlyProps {
  open: boolean;
}

const UploadTableReadOnly = (props: UploadTableReadOnlyProps) => {
  const uploadFileDataPreview = useSelector(
    (state: any) => state.prescription?.uploadFileDataPreview
  );
  const uploadedFileName = useSelector(
    (state: any) => state.prescription?.currentPrescription?.fileName
  );
  const uploadDifferentLabel = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );
  const filePreviewLabel = useTranslate(
    "steps.advancedPrescription.upload.filePreviewTitle"
  );
  return (
    <div
      className={"UploadTableReadOnly__container"}
      style={
        props.open
          ? {
              transform: "scale(1)",
            }
          : {
              transform: "scale(0.00001)",
              height: "0px",
              marginBottom: "0px",
            }
      }
    >
      <div className={"UploadTableReadOnly__container__previewLabel"}>
        {filePreviewLabel}
      </div>
      <div className={"UploadTableReadOnly__container__fileInfo"}>
        <img
          src={uploadFileDataPreview}
          alt="document"
          className="UploadTableReadOnly__container__"
        />
        <div className="" style={{ marginLeft: "19px" }}>
          <p className="UploadTableReadOnly__container__fileInfo__fileName">
            {uploadedFileName}
          </p>
          <p className="UploadTableReadOnly__container__fileInfo__uploadDifferent">
            {uploadDifferentLabel}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UploadTableReadOnly;
