//@ts-ignore
export default {
  oakley: {
    en_US: {
      steps: {
        tilesTitlePrefixProceed: "Proceed with",
        tilesTitlePrefixUpgrade: "Upgrade to",
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            addPrismValues: "Add prism values",
            pupillaryDistanceSubtitle: "You can select the default settings of <b>61 for women</b> and <b>64 for men</b> if you have an average or low prescription. If you have a strong prescription (or if you want to know your exact PD), please <a  data-element-id=\"X_X_LensPanel_AdvancedPrescription-MeasurePD\">measure your PD.",
            whatIsIt: "What is it?",
            commentsTitle: "Comments",
            alerts: {
              axisRangeError: "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError: "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning: "You’ve inserted 1 positive and 1 negative value, which is very rare. <br>We suggest double checking your prescription. If this is correct, you can proceed.",
              pdValueWarning: "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionBody: "but don’t worry, compatible frames are available",
              agreementText: "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist."
            },
            continueModal: {
              title: "Send prescription later",
              message: "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back"
            },
            incompatibleFrame:
              "We're sorry – the frame you’ve chosen isn't compatible with your prescription. Please select another style.\n </br>Have questions? You can <a href='https://www.oakley.com/en-us/contact'>contact our Customer Service</a> team.",
          },
          account: {
            card: {
              description: "Log in and select your prescription."
            },
            review: {
              title: "Loaded from the account"
            }
          },
          upload: {
            card: {
              description: "And we'll take care of the rest. We accept the following file formats: ###FILE_FORMATS### (max ###FILE_SIZE### MB) and IWork pages"
            },
            review: {
              title: "Uploaded"
            },
            prescriptionUploaded: "Prescription Uploaded",
            pdForm: {
              pdValueWarning: "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch."
            },
            filePreviewTitle: "Your prescription details"
          },
          doctor: {
            review: {
              title: "We'll call your doctor"
            },
            pdForm: {
              pdValueWarning: "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch."
            }
          },
          later: {
            card: {
              description: "We'll ask for it after you order, either by uploading it or having us call your doctor."
            }
          },
          californian: {
            toggle: "Are you a California resident?",
            info: "Please note that besides adding prescription values manually, California residents are also required to electronically transmit their valid prescription. Choose an option to proceed.",
            chooseTitle: "CHOOSE HOW TO SEND YOUR PRESCRIPTION",
            uploadTitle: "Upload",
            uploadDesc: "And we'll take care of the rest. We accept the following file formats: .png, .gif, .jpeg, .tiff, .bmp, .word, .pdf (max 10 MB) and IWork pages",
            uploadButton: "Upload",
            callMyDoc: "Call my doctor",
            callMyDocButton: "FIND YOUR DOCTOR",
            yes: "YES",
            no: "NO",
            modalMissingPrescription: {
              title: "Missing prescription values",
              subtitle: "If you don’t fill the values we won’t be able to suggest the right lens for you",
              bottonYes: "YES, CONTINUE",
              bottonNo: "NO, GO BACK"
            }
          }
        },
        type: {
          moreInfo: "More info"
        },
        treatmentsFamily: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included."
        },
        treatments: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included."
        },
        color: {
          polarized: "Polarized only",
          tooltip: {
            polarized: "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            blueLight: "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue"
          }
        },
        addOns: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included."
        },
        review: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology.",
          paymentInstallments: {
            installmentsLabel: "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            modal: {
              title: "Paying in installments",
              subtitle: "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.",
              affirm: "With Affirm, make 3 interest-free payments every two weeks with zero hidden fees. Payment options are offered by Affirm, are subject to an eligibility check, and may not be available in all states. CA residents: Loans by Affirm Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.",
              afterpay: "With Afterpay, make 4 interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###"
            }
          },
          tooltip: "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store."
        }
      },
      price: {
        vatIncluded: "Tax included"
      },
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      frameSize: "Frame size",
      yourSelections: "Your selections",
      generic: {
        modal: {
          close: ""
        }
      }
    },
    en_GB: {
      steps: {
        advancedPrescription: {
          manual: {
            whatIsIt: 'What is it?',
            alerts: {
              sphPositiveNegativeWarning: 'You’ve inserted 1 positive and 1 negative value, which is very rare. <br>We suggest you double check your prescription. If this is correct, you can proceed.',
              requiredToProceed: 'This is required to proceed',
            },
          },
          upload: {
            card: {
              title: 'Upload it now',
              description: 'And we\'ll take care of the rest. We accept the following file formats: .png, .gif, .jpeg, .tiff, .bmp, .word, .pdf (max 10 MB) and IWork pages',
            },
          },
          later: {
            card: {
              description: 'We\'ll ask for it after you order, either by uploading it or having us call your doctor.',
            },
          },
        },
        treatmentsFamily: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        thickness: {
          designTypeTitle: 'Choose your lens design',
        },
        treatments: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.',
        },
        color: {
          tooltip: {
            'switch': 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            polarized: 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            antiReflective: 'Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.',
            uvProtection: 'Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.',
            blueLight: 'Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue',
          },
        },
        addOns: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included',
        },
        review: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology.',
          paymentInstallments: {
            installmentsLabel: 'Pay over time in interest-free instalments with ###INSTALLMENTS###',
            modal: {
              title: 'Paying in instalments',
              subtitle: 'Want to spread out the cost of your order with ###BRAND###? \n Select ###INSTALLMENTS### as your payment method at checkout \n to pay in interest-free instalments, with no hidden fees.',
              affirm: 'With Affirm, make 3 interest-free payments every two weeks with zero hidden fees. Payment options are offered by Affirm, are subject to an eligibility check, and may not be available in all states. CA residents: Loans by Affirm Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.',
              afterpay: 'With Afterpay, make 4 interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.',
              klarna: 'With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###',
            },
          },
          tooltip: 'Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.',
        },
      },
      frameSize: 'Frame size',
      yourSelections: 'Your selections',
    },
    en_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            whatIsIt: 'What is it?',
            alerts: {
              sphPositiveNegativeWarning: 'You’ve inserted 1 positive and 1 negative value, which is very rare. <br>We suggest you double check your prescription. If this is correct, you can proceed.',
              requiredToProceed: 'This is required to proceed',
            },
          },
          upload: {
            card: {
              title: 'Upload it now',
              description: 'And we\'ll take care of the rest. We accept the following file formats: .png, .gif, .jpeg, .tiff, .bmp, .word, .pdf (max 10 MB) and IWork pages',
            },
          },
          later: {
            card: {
              description: 'We\'ll ask for it after you order, either by uploading it or having us call your doctor.',
            },
          },
        },
        treatmentsFamily: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        thickness: {
          designTypeTitle: 'Choose your lens design',
        },
        treatments: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.',
        },
        color: {
          tooltip: {
            'switch': 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            polarized: 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            antiReflective: 'Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.',
            uvProtection: 'Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.',
            blueLight: 'Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue',
          },
        },
        addOns: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included',
        },
        review: {
          subtitle: 'Your glasses will come with best-in-class Ray-Ban technology.',
          paymentInstallments: {
            installmentsLabel: 'Pay over time in interest-free instalments with ###INSTALLMENTS###',
            modal: {
              title: 'Paying in instalments',
              subtitle: 'Want to spread out the cost of your order with ###BRAND###? \n Select ###INSTALLMENTS### as your payment method at checkout \n to pay in interest-free instalments, with no hidden fees.',
              affirm: 'With Affirm, make 3 interest-free payments every two weeks with zero hidden fees. Payment options are offered by Affirm, are subject to an eligibility check, and may not be available in all states. CA residents: Loans by Affirm Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.',
              afterpay: 'With Afterpay, make 4 interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.',
              klarna: 'With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###',
            },
          },
          tooltip: 'Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.',
        },
      },
      frameSize: 'Frame size',
      yourSelections: 'Your selections',
    },
    en_MX: {
      steps: {
        advancedPrescription: {
          manual: {
            alerts: {
              sphPositiveNegativeWarning: 'You’ve inserted 1 positive and 1 negative value, which is very rare. <br>We suggest double checking your prescription. If this is correct, you can proceed.',
              requiredToProceed: 'This is required to proceed',
              pdValueWarning: 'La DP seleccionada está fuera del rango normal, te sugerimos que compruebes dos veces tu graduación. Puedes dejar el ajuste por defecto de 63, que es la medida media en adultos. Si necesitamos más información sobre tu graduación, uno de nuestros expertos se pondrá en contacto contigo.',
            },
          },
          upload: {
            card: {
              description: 'And we\'ll take care of the rest. We accept the following file formats: .png, .gif, .jpeg, .tiff, .bmp, .word, .pdf (max 10 MB) and IWork pages',
            },
            fileTooBigErrorTitle: 'EL ARCHIVO ES DEMASIADO PESADO',
            fileTooBigErrorDescription: 'Tu archivo es demasiado pesado. Considera ###FILE_SIZE### máximo',
            uploadDifferentFile: 'SUBIR UN ARCHIVO DISTINTO',
            changeMethod: 'Cambiar el método de envío',
            prescriptionUploaded: 'RECETA CARGADA',
          },
        },
        brand: {
          confirmedTitle: 'Lens brand:',
        },
        treatmentsFamily: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        thickness: {
          title: 'Selecciona el grosor de tus cristales',
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        treatments: {
          addToBag: 'Agregar a la cesta',
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.',
        },
        color: {
          tooltip: {
            'switch': 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            polarized: 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            antiReflective: 'Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.',
            uvProtection: 'Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.',
            blueLight: 'Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue',
          },
        },
        addOns: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.',
        },
        review: {
          title: 'Review your selections',
          subtitle: 'Your glasses will come with best in class Ray-Ban technology.',
          paymentInstallments: {
            installmentsLabel: 'Pay over time in interest-free installments with ###INSTALLMENTS###',
            modal: {
              title: 'Paying in installments',
              subtitle: 'Want to spread out the cost of your order with ###BRAND###? \n Select ###INSTALLMENTS### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.',
              affirm: 'With Affirm, make 3 interest-free payments every two weeks with zero hidden fees. Payment options are offered by Affirm, are subject to an eligibility check, and may not be available in all states. CA residents: Loans by Affirm Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.',
              afterpay: 'With Afterpay, make 4 interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.',
            },
          },
          tooltip: 'Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.',
        },
      },
      price: {
        totalFrameLens: {
          frameLensTotal: 'Precio del armazón + cristales',
        },
      },
      exitTitle: '\'¿SEGURO QUE QUIERES SALIR?\'',
      frameSize: 'Frame size',
      yourSelections: 'Your selections',
    },
    es_MX: {
      steps: {
        type: {
          title: "Selecciona tus necesidades de visión",
          confirmedTitle: "Uso"
        },
        brand: {
          confirmedTitle: "Lens brand:"
        },
        thickness: {
          title: "Selecciona el grosor de tus cristales",
          designTypeTitle: "Selecciona el diseño de la mica"
        },
        gvpTreatment: {
          title: "Choose your Great Value Package"
        },
        treatments: {
          addToBag: "Agregar a la cesta",
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included."
        },
        color: {
          confirmedTitle: "Color:",
          tooltip: {
            antiReflective: "Reduce los reflejos y proporciona un escudo invisible. Resiste el resplandor, el polvo y las manchas para disfrutar de una visión más clara en todos los sentidos.",
            uvProtection: "Capas protectoras duras en la parte delantera y trasera de las lentes para disfrutar de la máxima protección contra los rayos UV y los arañazos.",
            polarized: "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            switch: "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            antiScratch: "Protege la superficie de las lentes de los impactos fuertes"
          }
        },
        review: {
          title: "Revisa tus selecciones",
          subtitle: "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase.",
          paymentInstallments: {
            installmentsLabel: "Paga en cuotas sin intereses con\n###INSTALLMENTS###",
            modal: {
              title: "Pago a plazos",
              subtitle: "¿Quieres separar el costo de tu pedido con ###BRAND###? \n Selecciona ### INSTALLMENTS ### como tu método de pago al finalizar la compra \n para pagar en cuotas sin intereses, sin cargos ocultos.",
              affirm: "Con Affirm, realiza 3 pagos sin intereses cada dos semanas sin cargos ocultos. Affirm ofrece las opciones de pago, están sujetas a una verificación de elegibilidad y es posible que no esté disponible en todos los estados. Residentes de California: Los préstamos de Affirm Loan Services, LLC se otorgan de conformidad con una licencia de prestamista de California.",
              afterpay: "Con Afterpay, realiza 4 pagos sin intereses cada dos semanas sin cargos ocultos. Debes ser mayor de 18 años, residente de los EE. UU. y cumplir con los criterios de elegibilidad adicionales para calificar. Se pueden aplicar cargos por pagos atrasados. Haz clic aquí para conocer los términos completos. Los préstamos otorgados a los residentes de California se rigen por una licencia de la Ley de prestamistas de California.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###"
            }
          },
          tooltip: "Asegura tus lentes con una garantía extendida para reparaciones y reemplazos ilimitados en cualquier tienda LensCrafters.",
          seeDetails: "Ver detalles"
        },
        advancedPrescription: {
          manual: {
            title: "Introduce tu graduación",
            card: {
              title: "Introdúcela manualmente",
              description: "Este es nuestro método más rápido y popular. A continuación, te aconsejamos el tipo de lente más adecuado."
            },
            review: {
              title: "Introducida manualmente"
            },
            subtitle: "Añade los datos de tu graduación y te recomendaremos las mejores lentes para tus necesidades de visión.",
            alerts: {
              sphPositiveNegativeWarning: "Insertaste 1 valor positivo y 1 negativo, lo cual es muy poco común. Te sugerimos revisar tu receta. Si es correcto, puedes continuar.",
              pdValueWarning: "La DP seleccionada está fuera del rango normal, te sugerimos que compruebes dos veces tu graduación. Puedes dejar el ajuste por defecto de 63, que es la medida media en adultos. Si necesitamos más información sobre tu graduación, uno de nuestros expertos se pondrá en contacto contigo.",
              agreementText: "Al hacer clic en esta casilla, confirmo que los valores de la graduación introducidos anteriormente se han tomado de una graduación válida (no caducada) que me ha sido extendida, firmada por un optometrista u oftalmólogo autorizado."
            },
            continueModal: {
              title: "Enviar la graduación más tarde",
              message: "Al hacer clic en continuar, te saltarás el paso de la graduación. Te la pediremos una vez que hayas realizado tu pedido, ya sea subiéndola o dejando que hablemos con tu médico.",
              cancel: "NO, VOLVER"
            },
            digitalOptometry: {
              title: "CÓMO MEDIR TU DP",
              manuallyContent: "<div>Si quieres, puedes medirte tú mismo la DP siguiendo estos pasos:</div><ul><li>Coge una regla pequeña en una mano y ten a mano lápiz y papel.</li><li>Ahora, colócate a unos 20 cm de distancia de un espejo.</li><li>Cierra el ojo izquierdo y alinea el 0 mm sobre el centro de la pupila derecha.</li><li>Mide la distancia entre la pupila derecha y la izquierda.</li><li>El número que se alinea directamente sobre la pupila izquierda es tu DP (una DP media para un adulto oscila entre 58 y 65).</li></ul>",
              appSubtitle: "Tu distancia pupilar o DP es la distancia en milímetros (mm) desde el centro de una pupila hasta el centro de la otra."
            }
          },
          upload: {
            title: "Tu graduación",
            subtitle: "Antes de enviar tu graduación, comprueba la fecha de caducidad para asegurarte de que sigue siendo válida.",
            card: {
              title: "Súbela ahora"
            },
            fileTooBigErrorTitle: "EL ARCHIVO ES DEMASIADO PESADO",
            fileTooBigErrorDescription: "Tu archivo es demasiado pesado. Considera ###FILE_SIZE### máximo",
            uploadDifferentFile: "SUBIR UN ARCHIVO DISTINTO",
            prescriptionUploaded: "RECETA CARGADA",
            changeMethod: "Cambiar el método de envío"
          },
          later: {
            card: {
              description: "Te la pediremos una vez que hayas realizado tu pedido, ya sea subiéndola o dejando que hablemos con tu médico."
            }
          },
          review: {
            title: "Te la pediremos después de la compra"
          }
        },
        treatmentsFamily: {
          subtitle: "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
          clear: {
            description: "Micas tradicionales y translúcidas, perfectas para el uso diario"
          }
        },
        addOns: {
          confirmedTitle: "Completa tus micas",
          title: "Completa tus micas",
          subtitle: "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones."
        }
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Precio del armazón + cristales"
        }
      },
      noTreatment: "No treatment",
      exitTitle: "'¿SEGURO QUE QUIERES SALIR?'"
    },
    de_DE: {
      steps: {
        review: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert.",
          paymentInstallments: {
            installmentsLabel: "Ratenzahlung in zinslosen Raten mit ###INSTALLMENTS###",
            modal: {
              title: "Bezahlung in Raten",
              subtitle: "Sie möchten Ihre Bestellung bei ###BRAND### in Raten zahlen? \\Wählen Sie an der Kasse ###INSTALLMENTS### als Zahlungsmethode aus, um in zinslosen Raten zu zahlen, ohne versteckte Gebühren.",
              affirm: "Mit Affirm können Sie alle zwei Wochen 3 zinslose Raten zahlen, ohne versteckte Gebühren. Die Zahlungsoptionen werden von Affirm angeboten, unterliegen einer Berechtigungsprüfung und sind möglicherweise nicht in allen Ländern verfügbar. Kalifornien: Kredite von Affirm Loan Services, LLC werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt.",
              afterpay: "Mit Afterpay können Sie alle zwei Wochen 4 zinslose Raten zahlen, ohne versteckte Gebühren. Sie müssen über 18 Jahre alt sein, einen Wohnsitz in den USA haben und weitere Kriterien erfüllen, um die Ratenzahlung Anspruch nehmen zu können. Es können Säumnisgebühren anfallen. Klicken Sie hier, um die vollständigen Bedingungen einzusehen. Kredite an in Kalifornien ansässige Personen werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt."
            }
          },
          tooltip: "Schützen Sie Ihre Brille mit einer erweiterten Garantie für unbegrenzte Reparatur- und Ersatzleistungen in jedem LensCrafters-Geschäft.",
          seeDetails: "Details anzeigen"
        },
        treatments: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included."
        },
        thickness: {
          designTypeTitle: "Brillenglasdesign auswählen"
        },
        advancedPrescription: {
          manual: {
            title: "Gib dein Rezept ein",
            card: {
              description: "Dies ist unsere schnellste und beliebteste Methode. Danach empfehlen wir dir den am besten geeigneten Glastyp."
            },
            review: {
              title: "Manuell eingegeben"
            },
            subtitle: "Füge deine Rezeptwerte hinzu, und wir empfehlen dir das beste Glas für deine Sehbedürfnisse.",
            add: "ADD",
            iHaveTwoPd: "Ich habe 2 PD-Nummern",
            alerts: {
              sphPositiveNegativeWarning: "Sie haben 1 positiven und 1 negativen Wert eingegeben, was sehr selten ist. Bitte überprüfen Sie Ihre Eingabe. Falls Ihre Eingabe richtig ist, fahren Sie fort.",
              missingValues: "Bitte geben Sie die noch fehlenden Daten ein",
              pdValueWarning: "Die gewählte PD liegt außerhalb des normalen Bereichs, bitte überprüfe dein Rezept. Du kannst die Standardeinstellung von 63 beibehalten, da dies der durchschnittliche Wert für Erwachsene ist. Wenn wir weitere Informationen zu deinem Rezept benötigen, wird sich einer unserer Experten mit dir in Verbindung setzen."
            },
            continueModal: {
              title: "Rezept später versenden",
              message: "Wenn du auf „Weiter“ klickst, überspringst du den Schritt mit dem Rezept. Wir bitten dich nach deiner Bestellung darum. Du kannst es entweder hochladen oder wir kontaktieren deinen Arzt.",
              cancel: "NEIN, ZURÜCKGEHEN"
            },
            digitalOptometry: {
              title: "SO MISST DU DEINE PD",
              appSubtitle: "Deine Pupillendistanz (PD) ist der Abstand in Millimetern (mm) von der Mitte der einen Pupille zur Mitte der anderen."
            }
          },
          upload: {
            title: "Ihr Brillenpass",
            subtitle: "Bevor du dein Rezept einreichst, überprüfe bitte das Ablaufdatum, um sicherzustellen, dass es noch gültig ist.",
            prescriptionUploaded: "Rezept hochgeladen",
            fileTooBigErrorTitle: "Datei ist zu groß",
            fileTooBigErrorDescription: "Ihre Datei ist zu groß. Maximaler Upload ###FILE_SIZE### MB",
            changeMethod: "Übermittlungsart ändern",
            uploadDifferentFile: "Eine andere Datei hochladen",
            pdForm: {
              pupillaryDistance: "PD (Pupillendistanz)",
              whatIsIt: "Was ist das?",
              iHaveTwoPd: "Ich habe 2 PD-Nummern"
            },
            confirmAndContinue: "Bestätigen & Fortfahren"
          },
          later: {
            card: {
              description: "Wir bitten dich nach deiner Bestellung darum. Du kannst es entweder hochladen oder wir kontaktieren deinen Arzt."
            },
            review: {
              title: "Wir fragen nach dem Bezahlvorgang danach"
            }
          }
        },
        color: {
          tooltip: {
            antiScratch: "Schützt Ihre Glasoberfläche vor harten Stößen"
          }
        },
        treatmentsFamily: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz."
        },
        addOns: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz."
        }
      },
      frameSize: "Frame size"
    },
    de_AT: {
      steps: {
        review: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert."
        },
        treatments: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included."
        },
        advancedPrescription: {
          manual: {
            continueModal: {
              message: "Wenn du auf „Weiter“ klickst, überspringst du den Schritt mit dem Rezept. Wir bitten dich nach deiner Bestellung darum. Du kannst es entweder hochladen oder wir kontaktieren deinen Arzt."
            }
          }
        },
        color: {
          tooltip: {
            antiScratch: "Schützt Ihre Glasoberfläche vor harten Stößen"
          }
        },
        treatmentsFamily: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz."
        },
        addOns: {
          subtitle: "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz."
        }
      }
    },
    de_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            title: 'Gib dein Rezept ein',
            card: {
              description: 'Dies ist unsere schnellste und beliebteste Methode. Danach empfehlen wir dir den am besten geeigneten Glastyp.',
            },
            review: {
              title: 'Manuell eingegeben',
            },
            subtitle: 'Füge deine Rezeptwerte hinzu, und wir empfehlen dir das beste Glas für deine Sehbedürfnisse.',
            add: 'ADD',
            iHaveTwoPd: 'Ich habe 2 PD-Nummern',
            alerts: {
              sphPositiveNegativeWarning: 'Sie haben 1 positiven und 1 negativen Wert eingegeben, was sehr selten ist. Bitte überprüfen Sie Ihre Eingabe. Falls Ihre Eingabe richtig ist, fahren Sie fort.',
              pdValueWarning: 'Die gewählte PD liegt außerhalb des normalen Bereichs, bitte überprüfe dein Rezept. Du kannst die Standardeinstellung von 63 beibehalten, da dies der durchschnittliche Wert für Erwachsene ist. Wenn wir weitere Informationen zu deinem Rezept benötigen, wird sich einer unserer Experten mit dir in Verbindung setzen.',
            },
            continueModal: {
              title: 'Rezept später versenden',
              cancel: 'NEIN, ZURÜCKGEHEN',
            },
            digitalOptometry: {
              title: 'SO MISST DU DEINE PD',
              appSubtitle: 'Deine Pupillendistanz (PD) ist der Abstand in Millimetern (mm) von der Mitte der einen Pupille zur Mitte der anderen.',
            },
          },
          upload: {
            title: 'Ihr Brillenpass',
            subtitle: 'Bevor du dein Rezept einreichst, überprüfe bitte das Ablaufdatum, um sicherzustellen, dass es noch gültig ist.',
            prescriptionUploaded: 'Rezept hochgeladen',
            fileTooBigErrorTitle: 'Datei ist zu groß',
            fileTooBigErrorDescription: 'Ihre Datei ist zu groß. Maximaler Upload ###FILE_SIZE### MB',
            changeMethod: 'Übermittlungsart ändern',
            uploadDifferentFile: 'Eine andere Datei hochladen',
            pdForm: {
              pupillaryDistance: 'PD (Pupillendistanz)',
              whatIsIt: 'Was ist das?',
              iHaveTwoPd: 'Ich habe 2 PD-Nummern',
            },
            confirmAndContinue: 'Bestätigen & Fortfahren',
          },
          later: {
            card: {
              description: 'Wir bitten dich nach deiner Bestellung darum. Du kannst es entweder hochladen oder wir kontaktieren deinen Arzt.',
            },
            review: {
              title: 'Wir fragen nach dem Bezahlvorgang danach',
            },
          },
        },
        thickness: {
          designTypeTitle: 'Brillenglasdesign auswählen',
        },
        color: {
          tooltip: {
            antiReflective: 'Reduzieren Spiegelungen und dienen als unsichtbarer Schutz. Sie sind blendfrei, staub- und schmutzabweisend und sorgen für klare Sicht.',
            uvProtection: 'Harte Schutzschichten auf Vorder- und Rückseite der Gläser für maximalen Schutz vor UV-Strahlung und Kratzern.',
          },
        },
        review: {
          paymentInstallments: {
            installmentsLabel: 'Ratenzahlung in zinslosen Raten mit ###INSTALLMENTS###',
            modal: {
              title: 'Bezahlung in Raten',
              subtitle: 'Sie möchten Ihre Bestellung bei ###BRAND### in Raten zahlen? \\Wählen Sie an der Kasse ###INSTALLMENTS### als Zahlungsmethode aus, um in zinslosen Raten zu zahlen, ohne versteckte Gebühren.',
              affirm: 'Mit Affirm können Sie alle zwei Wochen 3 zinslose Raten zahlen, ohne versteckte Gebühren. Die Zahlungsoptionen werden von Affirm angeboten, unterliegen einer Berechtigungsprüfung und sind möglicherweise nicht in allen Ländern verfügbar. Kalifornien: Kredite von Affirm Loan Services, LLC werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt.',
              afterpay: 'Mit Afterpay können Sie alle zwei Wochen 4 zinslose Raten zahlen, ohne versteckte Gebühren. Sie müssen über 18 Jahre alt sein, einen Wohnsitz in den USA haben und weitere Kriterien erfüllen, um die Ratenzahlung Anspruch nehmen zu können. Es können Säumnisgebühren anfallen. Klicken Sie hier, um die vollständigen Bedingungen einzusehen. Kredite an in Kalifornien ansässige Personen werden gemäß einer kalifornischen Lizenz für Finanzdienstleister vergeben oder vermittelt.',
            },
          },
          tooltip: 'Schützen Sie Ihre Brille mit einer erweiterten Garantie für unbegrenzte Reparatur- und Ersatzleistungen in jedem LensCrafters-Geschäft.',
          seeDetails: 'Details anzeigen',
        },
      },
      frameSize: 'Frame size',
    },
    it_IT: {
      steps: {
        advancedPrescription: {
          manual: {
            alerts: {
              sphPositiveNegativeWarning: "Hai inserito un valore positivo e uno negativo, una combinazione molto rara. Per sicurezza, controlla la tua prescrizione. Se è tutto corretto, procedi pure.",
              pdValueWarning: "La DP non rientra nel range standard. Ti suggeriamo di ricontrollare la prescrizione. Puoi lasciare l’impostazione predefinita 63, che è la misura media per gli adulti. Se avremo bisogno di ulteriori informazioni sulla tua prescrizione, uno dei nostri esperti ti contatterà."
            }
          },
          doctor: {
            card: {
              description: "Chiederemo la tua prescrizione medica direttamente a lei/lui."
            }
          },
          later: {
            card: {
              description: "Più avanti nel corso dell’evasione del tuo ordine ti verrà richiesto di caricarla o di metterci in contatto con il tuo medico"
            },
            review: {
              title: "Lo chiederemo dopo il checkout"
            }
          }
        },
        treatmentsFamily: {
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi."
        },
        treatments: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included."
        },
        color: {
          tooltip: {
            antiScratch: "Protegge la superficie delle lenti dagli urti"
          }
        },
        addOns: {
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi"
        },
        review: {
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban.",
          paymentInstallments: {
            installmentsLabel: "Paga a rate a tasso zero con ###INSTALLMENTS###",
            modal: {
              title: "Pagamento a rate",
              subtitle: "Vuoi diluire il costo del tuo ordine ###BRAND###? \n Scegli ###INSTALLMENTS### come metodo di pagamento nel finalizzare l’acquisto \n e pagherai in comode rate a tasso zero, senza alcun costo aggiuntivo.",
              affirm: "Con Affirm puoi effettuare 3 pagamenti a tasso zero ogni due settimane, senza alcun costo aggiuntivo. Le opzioni di pagamento sono messe a disposizione da Affirm, sono soggette a verifica di idoneità e potrebbero non essere disponibili in tutti i paesi. Residenti in California: I finanziamenti di Affirm Loan Services, LLC sono concessi o organizzati in base a una licenza di California Finance Lender.",
              afterpay: "Con Afterpay puoi effettuare 4 pagamenti a tasso zero ogni due settimane. Solo per residenti degli USA che abbiano già compiuto i 18 anni di età, dietro soddisfazione degli ulteriori requisiti di idoneità. Potrebbero essere applicate penali per ritardo. Clicca qui per leggere tutti i termini. Per i residenti in California i finanziamenti vengono concessi o organizzati in base a una licenza di California Finance Lender.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###"
            }
          },
          tooltip: "Assicura i tuoi occhiali con una estensione di garanzia: comprenderà riparazioni senza alcun limite e sostituzione in qualunque negozio LensCrafters."
        }
      },
      price: {
        totalFrameLens: {
          total: "Total"
        }
      },
      insuranceSyncedLabel: "Insurance is synced",
      frameSize: "Frame size"
    },
    it_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            addPrismValues: 'Aggiungi valori prismatici',
          },
          later: {
            card: {
              description: 'Più avanti nel corso dell’evasione del tuo ordine ti verrà richiesto di caricarla o di metterci in contatto con il tuo medico.',
            },
          },
        },
        color: {
          tooltip: {
            antiReflective: 'Riduce i riflessi e offre uno stato protettivo invisibile.  Contrasta l\'abbagliamento, la polvere e le macchie, garantendo una visione più chiara.',
            uvProtection: 'Strati protettivi rigidi sulla parte anteriore e posteriore delle lenti per un\'elevata protezione dai raggi UV e una maggiore resistenza ai graffi.',
          },
        },
      },
    },
    fr_BE: {
      steps: {
        prescription: {
          title: 'Complétez votre ordonnance\'',
        },
        advancedPrescription: {
          manual: {
            digitalOptometry: {
              appSubtitle: 'Je pupilafstand (PD) is de afstand in millimeters (mm) van het midden van de ene pupil tot het midden van de andere.',
            },
          },
        },
        type: {
          title: 'Sélectionnez vos exigences de vue\'',
          confirmedTitle: 'Utilisez\'',
        },
        brand: {
          title: 'Choisissez la marque de vos verres\'',
        },
        thickness: {
          title: 'Sélectionnez l’épaisseur de vos verres\'',
        },
      },
    },
    fr_FR: {
      steps: {
        prescription: {
          title: "Complétez votre ordonnance'"
        },
        advancedPrescription: {
          manual: {
            title: "Saisissez votre ordonnance",
            card: {
              description: "C'est notre méthode la plus rapide et la plus courante. Nous vous recommanderons alors le type de verre le plus approprié."
            },
            review: {
              title: "Saisie manuelle"
            },
            subtitle: "Ajoutez les valeurs de votre ordonnance, puis nous vous recommanderons le verre le mieux adapté à vos besoins visuels.",
            alerts: {
              sphPositiveNegativeWarning: "Vous avez saisi 1 valeur positive et 1 valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance. Si ces valeurs sont correctes, veuillez continuer.",
              pdValueWarning: "L'EP sélectionné est en dehors de la plage habituelle, veuillez recontrôler votre ordonnance. Vous pouvez laisser le paramètre par défaut de 63, qui est la mesure moyenne des adultes. Pour toute information sur votre ordonnance, vous serez contacté(e) par l'un de nos experts."
            },
            continueModal: {
              message: "En cliquant sur Continuer, vous ignorerez l'étape de l’ordonnance. Nous vous la demanderons après votre commande, vous pourrez alors la télécharger ou nous demander d’appeler votre ophtalmologiste.",
              cancel: "NON, REVENIR EN ARRIÈRE"
            },
            digitalOptometry: {
              title: "COMMENT MESURER VOTRE EP",
              manuallyContent: "<div>Si vous le souhaitez, vous pouvez mesurer vous-même votre EP en suivant ces étapes :</div><ul><li>Prenez une petite règle dans une main, et ayez un crayon et un papier à portée de main.</li><li>Placez-vous maintenant à environ 20 cm d'un miroir.</li><li>Fermez votre œil gauche et alignez le 0 mm sur le centre de votre pupille droite</li><li>Mesurez la distance entre votre pupille droite et votre pupille gauche.</li><li>Le chiffre qui se trouve directement au-dessus de votre pupille gauche est votre EP (l’EP moyen d'une personne adulte se situe entre 58 et 65).</li></ul>",
              appSubtitle: "L'écart pupillaire, ou EP, est la distance en millimètres (mm) entre le centre d'une pupille et le centre de l'autre pupille."
            }
          },
          upload: {
            subtitle: "Avant d’envoyer votre ordonnance, veuillez vérifier la date d'expiration pour vous assurer qu'elle est encore valable.",
            prescriptionUploaded: "Ordonnance téléchargée"
          },
          later: {
            card: {
              description: "Nous vous la demanderons après votre commande, vous pourrez alors la télécharger ou nous demander d’appeler votre ophtalmologiste."
            }
          },
          review: {
            title: "Nous vous la demanderons après le paiement"
          }
        },
        brand: {
          confirmedTitle: "Marque'"
        },
        treatmentsFamily: {
          subtitle: "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse."
        },
        thickness: {
          confirmedTitle: "Épaisseur'",
          title: "Sélectionnez l’épaisseur de vos verres'"
        },
        treatments: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included."
        },
        color: {
          tooltip: {
            antiScratch: "Protège la surface de vos verres contre les chocs violents"
          }
        },
        addOns: {
          subtitle: "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse"
        },
        review: {
          subtitle: "Vos lunettes seront équipées de la meilleure technologie Ray-Ban.",
          paymentInstallments: {
            installmentsLabel: "Payez en plusieurs versements sans intérêt avec ###INSTALLMENTS###",
            modal: {
              title: "Payer en plusieurs versements",
              subtitle: "Vous souhaitez échelonner le paiement votre commande avec ###BRAND### ? \\Sélectionnez ###INSTALLMENTS### comme mode de paiement au moment de payer afin de régler le montant en plusieurs fois sans intérêts et sans frais cachés.",
              affirm: "Avec Affirm, payez en 3 versements sans intérêts toutes les deux semaines, sans frais cachés. Les options de paiement sont proposées par Affirm, sont soumises à une vérification d'admissibilité et peuvent ne pas être disponibles dans tous les États. Résidents de Californie : Les prêts proposés par Affirm Loan Services, LLC sont accordés ou organisés conformément à une licence de prêteur de la Californie.",
              afterpay: "Avec Afterpay, payez en 4 versements sans intérêts toutes les deux semaines, sans frais cachés. Vous devez avoir plus de 18 ans, être résident aux États-Unis et répondre à d'autres critères d'admissibilité pour être admissible. Des frais de retard peuvent s'appliquer. Cliquez ici pour consulter toutes les conditions. Les prêts aux résidents de la Californie sont accordés ou organisés conformément à une licence prévue par loi sur les prêteurs de la Californie (California Finance Lenders Law)",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###"
            }
          },
          insuranceButtonLabel: "Appliquer l’assurance'",
          tooltip: "Assurez vos lunettes avec une garantie étendue pour une réparation et un remplacement sans limitations dans n'importe quel magasin LensCrafters.",
          seeDetails: "Voir les détails"
        }
      },
      prescription: {
        toggleLabel: "Ajoutez les valeurs de votre ordonnance, nous vous aiderons à trouver la paire parfaite pour vos besoins'",
        prescriptionTitle: "Ajoutez les valeurs de votre ordonnance, nous vous montrerons le verre répondant parfaitement à vos besoins.'",
        readPrescription: "Comment lire votre ordonnance'",
        addLabel: "J’ai aussi une valeur d’addition'",
        applyButton: "Appliquer et continuer'",
        submitButton: "Envoyer'",
        clearButton: "Effacer tout'",
        skipButton: "Ignorer pour l’instant'",
        compatibleFramesButton: "Acheter des montures compatibles'",
        doctorDetails: "Nous vous demanderons une copie de votre ordonnance ou les coordonnées de votre médecin pendant ou après le paiement'",
        missingValueError: "Ajoutez vos valeurs pour continuer'",
        positiveNegativeError: "Vous avez entré une valeur positive et une valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance.'",
        compatibleFrameError: "Désolés ! La monture que vous avez choisie n’est pas compatible avec les valeurs de votre correction.'",
        axisError: "'Les valeurs d’AXE que vous avez saisies sont incorrectes !'",
        axisSubError: "Ces valeurs doivent entre 0 et 180.'",
        axisSubErrorZero: "Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.'",
        editLabel: "Modifier la correction'",
        twoPDNumbers: "J’ai deux valeurs d'EP'",
        missingInputMessage: "Ajoutez les valeurs manquantes pour compléter le téléchargement de votre ordonnance et nous vous recommanderons le verre le plus adapté.'",
        defaultInputMessage: "Ajoutez vos valeurs SPH et CYL et nous vous recommanderons le verre le plus adapté aux besoins de votre vue.'",
        skipMessage: "Nous vous demanderons de compléter ou de télécharger votre ordonnance plus tard'",
        readyMessage: "Nous sommes maintenant en mesure de vous proposer le verre qui vous convient le mieux  !'",
        bestPrescription: "Le meilleur pour votre correction'",
        proceedCheckbox: "Obligatoire pour continuer'",
        proceedCheckboxError: "Ou {0} paiements de {1}{2} avec'"
      },
      price: {
        startingAt: "Prix partant de'"
      },
      learnMoreMade: "Voir comment c’est fait'",
      learnMoreTreatment: "Voir le traitement'",
      exitTitle: "ÊTES-VOUS SÛR DE VOULOIR SORTIR ?'",
      exitSubtitle: "Votre choix de verres ne sera pas enregistré'",
      exitContinueEditing: "Continuer la modification'",
      exitSave: "Enregistrer et continuer le shopping'",
      insuranceButtonLabel: "Ajouter des prestations sociales'",
      insuranceSyncedLabel: "Prestations sociales appliquées'",
      applyingInsurance: "Application des prestations sociales en cours'",
      frameSize: "Frame size"
    },
    fr_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            digitalOptometry: {
              appSubtitle: 'L\'écart pupillaire, ou EP, est la distance en millimètres (mm) entre le centre d\'une pupille et le centre de l\'autre pupille.',
            },
          },
        },
        brand: {
          confirmedTitle: 'Marque\'',
        },
        color: {
          tooltip: {
            antiReflective: 'Réduit les reflets et fournit une protection visible. Résiste à l\'éblouissement, à la poussière et aux taches pour une vision plus claire.',
            uvProtection: 'Couches de protection dures sur l’avant et l’arrière des verres pour protéger totalement des UV et des rayures.',
          },
        },
      },
      insuranceSyncedLabel: 'Prestations sociales appliquées\'',
    },
    nl_NL: {
      steps: {
        prescription: {
          title: "Rond jouw brilrecept af'"
        },
        advancedPrescription: {
          manual: {
            title: "Voer je voorschrift in",
            card: {
              title: "Voer handmatig in",
              description: "Dit is onze snelste en populairste methode. Wij zullen dan het meest geschikte type glas aanbevelen."
            },
            review: {
              title: "Handmatig ingevoerd"
            },
            subtitle: "Voeg je voorschriftwaarden toe en wij adviseren je het beste glas voor jouw zichtbehoeften.",
            alerts: {
              sphPositiveNegativeWarning: "Je hebt 1 positieve en 1 negatieve waarde ingevoerd, dat is erg bijzonder. Ik adviseer je om het brilrecept te controleren. Als dit juist is, kun je doorgaan.",
              pdValueWarning: "De geselecteerde PD ligt buiten het normale bereik. We raden je aan je voorschrift te controleren. Je kunt de standaardinstelling van 63 laten staan, wat de gemiddelde meting voor volwassenen is. Als we meer informatie over je voorschrift nodig hebben, neemt een van onze experts contact met je op."
            },
            continueModal: {
              title: "Voorschrift later sturen",
              message: "Door op doorgaan te klikken, sla je de stap van het voorschrift over. We vragen er na je bestelling naar, hetzij door het te uploaden of door ons je arts te laten bellen.",
              cancel: "NEE, GA TERUG"
            },
            digitalOptometry: {
              title: "JE PUPILAFSTAND METEN",
              manuallyContent: "<div>Als je wilt, kun je zelf je PD meten door deze stappen te volgen:</div><ul><li>Pak een kleine liniaal in één hand en houd potlood en papier bij de hand.</li><li>Plaats jezelf nu ongeveer 20 cm verwijderd van een spiegel.</li><li>Sluit je linkeroog en lijn de 0 mm uit over het midden van je rechterpupil.</li><li>Meet de afstand van je rechter- tot je linkerpupil.</li><li>Het getal dat direct boven je linkerpupil staat, is je PD (een gemiddelde PD voor een volwassene varieert tussen 58 en 65).</li></ul>",
              appSubtitle: "Je pupilafstand (PD) is de afstand in millimeters (mm) van het midden van de ene pupil tot het midden van de andere."
            }
          },
          upload: {
            subtitle: "Controleer de vervaldatum voordat je je voorschrift indient om er zeker van te zijn dat het nog geldig is.",
            card: {
              title: "Nu uploaden"
            },
            prescriptionUploaded: "Voorschrift geüpload"
          },
          later: {
            card: {
              title: "Later sturen",
              description: "We vragen er na je bestelling naar, hetzij door het te uploaden of door ons je arts te laten bellen."
            }
          },
          review: {
            title: "We vragen ernaar na het afrekenen"
          }
        },
        type: {
          title: "Selecteer de benodigde sterkte'",
          confirmedTitle: "Gebruik'"
        },
        brand: {
          title: "Kies het merk brillenglazen'",
          confirmedTitle: "Merk'"
        },
        treatmentsFamily: {
          subtitle: "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming."
        },
        gvpTreatment: {
          title: "Rond jouw voordeelpakket af'"
        },
        thickness: {
          confirmedTitle: "Dikte'",
          title: "Selecteer de dikte van de brillenglazen'"
        },
        treatments: {
          addToBag: "AAN WINKELMAND TOEVOEGEN",
          title: "Rond jouw brillenglas af'",
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Behandelingen'",
          extraTreatments: {
            antiScratch: {
              title: "Krasbestendig'"
            },
            antiReflective: {
              value: "Antireflexbehandeling'"
            },
            uvProtection: {
              title: "UV-bescherming en krasbestendig'"
            }
          }
        },
        color: {
          tooltip: {
            uvProtection: "Vermindert reflecties en zorgt voor een onzichtbaar schild. De coatings zijn bestand tegen verblinding, stof en vlekken, voor een helderder zicht.",
            antiReflective: "Brillen op sterkte worden geleverd op 12 augustus. Als je je brilrecept al hebt geüpload, beginnen we met verifiëren (meestal binnen 2-3 werkdagen).",
            antiScratch: "Beschermt je brillenglazen tegen harde schokken"
          }
        },
        addOns: {
          subtitle: "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming"
        },
        review: {
          subtitle: "Jouw bril wordt geleverd met de beste technologie van Ray-Ban.",
          paymentInstallments: {
            installmentsLabel: "Gespreide betaling met rentevrije afbetalingstermijnen met ###INSTALLMENTS###",
            modal: {
              title: "Betaling met afbetalingstermijnen",
              subtitle: "Wil je de kosten van jouw bestelling spreiden met ###BRAND###? \n Selecteer tijdens het afrekenen ###INSTALLMENTS### als betaalwijze \n om te betalen met rentevrije afbetalingstermijnen, zonder verborgen kosten.",
              affirm: "Met Affirm, kun je afbetalen met 3 rentevrije betalingen die elke twee weken worden afgeschreven, zonder verborgen kosten. De betaalmogelijkheden worden aangeboden door Affirm. Ze worden onderworpen aan een toetsing van de acceptatiecriteria en de beschikbaarheid verschilt per land. Inwoners van Californië: Leningen die worden verstrekt door Affirm Loan Services, LLC vinden plaats of worden geregeld in overeenkomst met een Californische licentie voor kredietgevers.",
              afterpay: "Met Afterpay, kun je afbetalen met 4 rentevrije betalingen die elke twee weken worden afgeschreven. Je moet 18 jaar of ouder zijn, in de VS wonen en aan extra acceptatiecriteria voldoen om hier aanspraak op te kunnen maken. Er kunnen kosten aan verbonden zijn. Klik hier voor alle voorwaarden. Leningen die verstrekt worden aan inwoners in Californië vinden plaats of worden geregeld in overeenkomst met een Californische licentie voor kredietgevers.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###"
            }
          },
          tooltip: "Verzeker je bril met een verlengde garantie voor onbeperkte reparatie en vervanging in elke LensCrafters-winkel.",
          seeDetails: "Details tonen"
        }
      },
      prescription: {
        toggleLabel: "Voeg de gegevens van jouw brilrecept in, we helpen je het juiste brillenglas voor jouw sterkte te vinden.'",
        prescriptionTitle: "Voeg de gegevens van jouw brilrecept in, we tonen je het juiste brillenglas voor jouw sterkte.'",
        readPrescription: "Hoe lees je het brilrecept'",
        addLabel: "Ik heb ook een ADD-waarde'",
        applyButton: "Toepassen en doorgaan'",
        submitButton: "Verzenden'",
        clearButton: "Alle wissen'",
        skipButton: "Tijdelijk overslaan'",
        compatibleFramesButton: "Koop compatibele monturen'",
        doctorDetails: "Na het afrekenen vragen we je om een kopie van jouw brilrecept of de gegevens van jouw oogarts'",
        missingValueError: "Voeg jouw gegevens toe om door te gaan'",
        positiveNegativeError: "Je hebt één positieve waarde en één negatieve waarde ingevoerd. Dit is bijzonder zeldzaam. Ik adviseer je om het brilrecept te controleren.'",
        compatibleFrameError: "'Helaas! Het montuur dat je hebt gekozen is niet geschikt voor jouw brilrecept.'",
        compatibleFrameSubLabel: "Geen probleem. We hebben monturen die voor jouw brilrecept geschikt zijn.'",
        axisError: "'De AS-waarden die je hebt ingevoerd zijn niet juist!'",
        axisSubError: "Deze moeten tussen 0 en 180 liggen.'",
        axisSubErrorZero: "Als de CYL-waarde hoger of lager is dan 0, dan kunnen de AS-waarden niet 0 zijn.'",
        editLabel: "Brilrecept bewerken'",
        pdHelp: "Wat is dit?'",
        twoPDNumbers: "Ik heb twee PD-nummers'",
        missingInputMessage: "Voeg de ontbrekende gegevens in zodat je het uploaden van het brilrecept kunt afronden en we zullen je het meest geschikte brillenglas aanbevelen.'",
        defaultInputMessage: "Voeg jouw SPH- en CYL-waarden in en we zullen je het brillenglas aanbevelen dat het meest geschikt is voor jouw sterkte.'",
        skipMessage: "We vragen later om het brilrecept af te ronden of te uploaden'",
        readyMessage: "Nu kunnen we je het beste brillenglas aanbevelen!'",
        bestPrescription: "Het beste voor jouw brilrecept'",
        proceedCheckbox: "Vereist om door te gaan'",
        proceedCheckboxError: "Of {0} betalingen van {1}{2} met'"
      },
      price: {
        startingAt: "Vanaf'"
      },
      learnMoreMade: "Zie hoe hij wordt gemaakt'",
      learnMoreTreatment: "Bekijk de behandeling'",
      exitTitle: "WEET JE HET ZEKER DAT JE WILT AFSLUITEN?'",
      exitSubtitle: "De gekozen brillenglazen worden niet opgeslagen'",
      exitContinueEditing: "Doorgaan met bewerken'",
      exitSave: "Opslaan en verder winkelen'",
      insuranceButtonLabel: "Verzekeringsuitkeringen toevoegen'",
      insuranceSyncedLabel: "Verzekeringsuitkeringen toegepast'",
      applyingInsurance: "Verzekeringsuitkeringen worden toegepast'",
      frameSize: "Frame size"
    },
    nl_BE: {},
    no_NO: {
      steps: {
        advancedPrescription: {
          manual: {
            card: {
              description: "Det er den raskeste og mest populære metoden. Her anbefaler vi det brilleglasset som passer best."
            },
            pupillaryDistanceSubtitle: "Du kan velge standardinnstillingene <b>61 for kvinner</b> og <b>64 for menn</b> hvis du bruker en gjennomsnittlig eller liten styrke. Hvis du bruker stor styrke (eller vil vite nøyaktig pupillavstand), må du <a  data-element-id=\"X_X_LensPanel_AdvancedPrescription-MeasurePD\">måle pupillavstanden din</a>",
            continueModal: {
              cancel: "Nei, gå tilbake"
            },
            digitalOptometry: {
              appSubtitle: "Pupillavstanden (PD) er avstanden i millimeter (mm) fra midten av én pupill til midten av den andre"
            }
          },
          upload: {
            prescriptionUploaded: "Brilleseddel er lastet opp"
          },
          later: {
            card: {
              description: "Vi ber om den etter bestillingen, enten ved å laste den opp eller ved at vi ringer legen din."
            }
          }
        },
        treatmentsFamily: {
          subtitle: "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig og UV-beskyttelse er inkludert."
        },
        treatments: {
          subtitle: "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig behandling og UV-beskyttelse er inkludert."
        },
        color: {
          tooltip: {
            uvProtection: "Harde beskyttelseslag foran og bak på brilleglassene for maksimal UV- og ripebeskyttelse."
          }
        },
        addOns: {
          subtitle: "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig og UV-beskyttelse er inkludert."
        },
        review: {
          subtitle: "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi."
        }
      },
      frameSize: "Størrelse"
    },
    sv_SE: {
      steps: {
        prescription: {
          title: "Slutför ditt glasögonrecept'"
        },
        advancedPrescription: {
          manual: {
            alerts: {
              sphPositiveNegativeWarning: "Du angav ett positivt och ett negativ värde vilket är mycket sällsynt. Vi rekommenderar att du dubbelkollar ditt glasögonrecept. Om det är rätt kan du fortsätta."
            }
          }
        },
        type: {
          title: "Välj dina synbehov'"
        },
        brand: {
          title: "Välj ditt linsmärke'",
          confirmedTitle: "Varumärke'"
        },
        treatmentsFamily: {
          subtitle: "Dina glasögon har förstklassig Ray-Ban-teknik. Reptålighet och UV-skydd ingår."
        },
        gvpTreatment: {
          title: "Slutför ditt Prisvärda paket'"
        },
        thickness: {
          confirmedTitle: "Tjocklek'",
          title: "Välj din linstjocklek'"
        },
        treatments: {
          addToBag: "Lägg till i varukorg'",
          title: "Slutför din lins'",
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Behandlingar'",
          extraTreatments: {
            antiScratch: {
              title: "Reptålighet'"
            },
            antiReflective: {
              value: "Antireflektionsbehandling'"
            },
            uvProtection: {
              title: "UV-skydd och reptålighet'"
            }
          }
        },
        color: {
          tooltip: {
            antiScratch: "Skyddar ytan på din lins mot kraftig yttre påverkan."
          }
        },
        addOns: {
          subtitle: "Dina glasögon har förstklassig Ray-Ban-teknik. Reptålighet och UV-skydd ingår"
        },
        review: {
          subtitle: "Dina glasögon har förstklassig Ray-Ban-teknik.",
          paymentInstallments: {
            installmentsLabel: "Betala över tid genom räntefria avbetalningar med ###INSTALLMENTS###",
            modal: {
              title: "Betalning genom avbetalningar",
              subtitle: "Vill du sprida ut kostnaden för din beställning med ###BRAND###? \n Välj ###INSTALLMENTS### som ditt betalningssätt vid utcheckningen \n för att betala genom räntefria avbetalningar, utan dolda avgifter.",
              affirm: "Gör tre räntefria betalningar med Affirm varannan vecka utan några dolda avgifter. Betalningsalternativ erbjuds av Affirm, genomgår en behörighetskontroll och kanske inte är tillgängliga i alla delstater. Invånare i Kalifornien: Lån via lånetjänsten LCC hos Affirm genomförs eller utformas i enlighet med en licens från en långivare i Kalifornien.",
              afterpay: "Gör fyra räntefria betalningar med Afterpay varannan vecka. Du måste ha fyllt 18 år, vara bosatt i USA och uppfylla ytterligare behörighetskriterier för att kvalificera dig. Förseningsavgifter kan tas ut. Klicka här för fullständiga villkor. Lån till invånare i Kalifornien genomförs eller utformas i enlighet med en licens enligt Kaliforniens finansieringslag.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###"
            }
          },
          insuranceButtonLabel: "Applicera försäkring'",
          tooltip: "Försäkra dina glasögon med en utökad garanti för obegränsad reparation och utbyte hos valfri LensCrafters-butik."
        }
      },
      prescription: {
        toggleLabel: "Lägg till dina glasögonreceptvärden, så hjälper vi dig att hitta den perfekta linsen för dina synbehov.'",
        prescriptionTitle: "Lägg till dina glasögonreceptvärden, så visar vi dig den perfekta linsen för dina synbehov.'",
        readPrescription: "Hur du läser ditt glasögonrecept'",
        addLabel: "Jag har även ett ADD-värde'",
        applyButton: "Applicera och fortsätt'",
        submitButton: "Skicka'",
        clearButton: "Rensa alla'",
        skipButton: "Hoppa över tills vidare'",
        compatibleFramesButton: "Köp kompatibla bågar'",
        doctorDetails: "Vi kommer att be om en kopia på ditt glasögonrecept eller kontaktuppgifterna till din optiker under eller efter utcheckningen.'",
        missingValueError: "Lägg till dina värden för att fortsätta'",
        positiveNegativeError: "Du skrev in ett positivt värde och ett negativt värde, vilket är ganska sällsynt. Vi rekommenderar att du dubbelkollar ditt glasögonrecept.'",
        compatibleFrameError: "Tyvärr! Bågen som du har valt fungerar inte med dina glasögonreceptvärden.'",
        compatibleFrameSubLabel: "Inga problem, vi har bågar som passar ditt glasögonrecept.'",
        axisError: "AXIS-värdena som du har angett är inte korrekta!'",
        axisSubError: "Dessa värden måste vara mellan 0 och 180.'",
        axisSubErrorZero: "Om CYL-värdet är högre eller lägre än 0, kan AXIS-värdena inte vara 0.'",
        editLabel: "Redigera glasögonrecept'",
        pdHelp: "Vad är detta?'",
        twoPDNumbers: "Jag har två PD-nummer.'",
        missingInputMessage: "Lägg till de av dina värden som saknas för att slutföra uppladdningen av ditt glasögonrecept så kommer vi att rekommendera den lins som är mest lämplig.'",
        defaultInputMessage: "Lägg till dina SPH- och CYL-värden så kommer vi att rekommendera den lins som är mest lämplig för dina synbehov.'",
        skipMessage: "Vi kommer att be dig slutföra eller ladda upp ditt glasögonrecept senare.'",
        readyMessage: "Vi kan inte föreslå den rätta linsen för dig!'",
        bestPrescription: "Bäst för ditt glasögonrecept'",
        proceedCheckbox: "Du måste fortsätta'",
        proceedCheckboxError: "Eller {0} betalningar av {1}{2} med'"
      },
      price: {
        startingAt: "Lägsta pris'"
      },
      learnMoreMade: "Se hur den görs'",
      learnMoreTreatment: "Se behandlingen'",
      insuranceButtonLabel: "Lägg till försäkringsförmåner'",
      insuranceSyncedLabel: "Försäkringsförmåner applicerade'",
      applyingInsurance: "Applicera försäkringsförmåner'",
      frameSize: "Frame size",
      yourSelections: "Dina Val"
    },
    ja_JP: {
      steps: {
        advancedPrescription: {
          title: "処方箋数値をご入力ください",
          manual: {
            title: "処方箋を入力してください",
            card: {
              title: "ご自身で入力",
              description: "医療機関発行かつ有効期限内の処方箋をお持ちの方対象。以前、ご自身で入力された事のある方はご検討ください。"
            },
            review: {
              title: "お客様による入力"
            },
            subtitle: "医療機関発行の有効な処方箋に基づいた数値をご入力ください。",
            sphere: "スフィア (SPH)",
            cylinder: "シリンダー (CYL)",
            alerts: {
              sphPositiveNegativeWarning: "プラス値1つとマイナス値1つを入力されましたが、これは非常に稀なケースです。処方せんをもう一度確認するようにお勧めいたします。もしこれが正確な値の場合、次に進んでください。",
              incompatibleWithPrescriptionTitle: "ご入力いただいた度数では製作できません。恐れ入りますが、別のフレームをご検討下さい。",
              agreementText: "私（注文者）は、上記入力された情報が、医療機関発行の有効な処方箋に基づいた数値であることを確認しました。"
            },
            continueModal: {
              title: "あとで処方箋を送信する",
              message: "お手元に有効な処方箋をお持ちでない方は、後ほど提出いただくことも可能です。お先に購入手続きへお進みください。",
              continue: "続行する",
              cancel: "戻る"
            },
            digitalOptometry: {
              title: "瞳孔間距離（PD）の測定方法",
              manuallyContent: "<div>以下の手順により、PDを自分で測定することもできます。</div><ul><li>片手に小さな定規を持ち、紙と鉛筆を用意してください。鏡から約20cm離れたところに立ってください。左目を閉じ、定規の0mmの位置を右目の瞳孔の中心に合わせます。右目の瞳孔から左目の瞳孔までの距離を測ってください。左目の瞳孔にある定規の数値がPDです（成人の平均的なPDは58～65です）。</li></ul>",
              appSubtitle: "瞳孔間距離（PD）とは、片方の瞳孔の中心からもう片方の瞳孔の中心までの距離をあらわし、ミリメートル（mm）で表記されます。"
            },
            manualPreviewTitle: "処方箋の数値"
          },
          upload: {
            title: "お客様の処方箋",
            subtitle: "次へ進む前に、処方箋の有効期限をお確かめください。",
            card: {
              title: "今すぐアップロード",
              description: "医療機関発行かつ有効期限内の処方箋データをお持ちの方対象。処方箋の書面をスキャンまたは写真撮影した画像をアップロードしてください。対応形式：jpeg、pdf、png　推奨サイズ：1MB前後"
            },
            uploadDifferentFile: "別のファイルをアップロード",
            changeMethod: "別の手段に変更",
            confirmAndContinue: "確定して進む",
            prescriptionUploaded: "処方箋がアップロードされました",
            fileTooBigErrorTitle: "ファイルサイズ容量オーバー",
            fileTooBigErrorDescription: "アップロードいただいたファイルの容量を調整いただくか、別の形式にてご登録ください。対応形式：jpeg、pdf、png　推奨サイズ：1MB前後",
            review: {
              title: "アップロード完了"
            },
            filePreviewTitle: "処方箋の詳細"
          },
          later: {
            card: {
              title: "あとで送信",
              description: "まだ処方箋がお手元に無い方対象。ご準備でき次第、ウエブサイト通じてアップロードしてください。"
            }
          },
          review: {
            title: "度付きアイウエア（RX）の製作には医療機関発行の有効な処方箋が必要となります。購入手続き後にご提出下さい。"
          }
        },
        type: {
          confirmedTitle: "レンズの補正機能"
        },
        treatmentsFamily: {
          title: "ご自分のレンズタイプを選択",
          subtitle: "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。スクラッチ耐性と紫外線保護が含まれています。"
        },
        thickness: {
          designTypeTitle: "レンズのテクノロジーを選択"
        },
        treatments: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          extraTreatments: {
            antiScratch: {
              title: "キズ防止コーティング"
            },
            uvProtection: {
              title: "UVカット加工"
            }
          }
        },
        lensColor: {
          confirmedTitle: "レンズテクノロジー＆カラー",
          detailBox: {
            lightTransmission: "可視光線透過率",
            lightConditions: "日照状況"
          }
        },
        color: {
          confirmedTitle: "レンズテクノロジー＆カラー",
          titleMobile: "レンズカラーを選択",
          tooltip: {
            antiScratch: "レンズ表面を強い衝撃から守ります"
          }
        },
        addOns: {
          title: "レンズのタイプを選択",
          subtitle: "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。スクラッチ耐性と紫外線保護が含まれています"
        },
        review: {
          subtitle: "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。",
          paymentInstallments: {
            installmentsLabel: "###INSTALLMENTS###で無利子の分割払い",
            modal: {
              title: "分割払い",
              subtitle: "###BRAND###でご注文を分割してみませんか? \n 会計時の支払い方法に###INSTALLMENTS###を選択し \n 手数料なしで無利子の分割払いをご利用ください。",
              affirm: "Affirmなら、手数料ゼロ、無利子の分割で2週間毎に3回払いにできます。Affirmの支払い方法は、条件確認があり、すべてが承認されるとは限らないことをご了承ください。カリフォルニア居住者：Affirm Loan Services, LLCのローンはCalifornia Finance Lenderライセンスによるかその規定に従います。",
              afterpay: "Afterpayなら、無利子で2週間毎に4回払いにできます。18歳以上でアメリカ居住の方が対象で、 承認にはさらに追加の条件があります。遅滞には手数料が課されます。ここをクリックし約定を完了してください。カリフォルニア居住者の方向けのローンは、California Finance Lenders法のライセンスによるかその規定に従います。",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###"
            }
          },
          tooltip: "アイウェアの延長保証は、全てのLensCrafters ストアで無制限の修理や交換を受けられます。",
          seeDetails: "詳細"
        }
      },
      prescription: {
        applyButton: "続ける"
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "フレーム＋レンズ価格"
        }
      },
      exitYes: "終了する",
      frameSize: "Frame size"
    },
  },
};
