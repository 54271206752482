import React, { useEffect, useMemo, useState } from 'react';
import "./default.module.scss";
import { BrandComponent } from '../common/BrandComponent';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from "react-currency-format";
import { useAllCurrentPackages } from '../../configurator/hooks';
import { mixAndMatchActions } from '../../redux/slices/mixAndMatch';
import { useTranslation } from '../../configurator/translations/TranslationHooks';
interface LensesSection {

}

export function LensesSection({ }: LensesSection) {
  const [showPrescriptionLenses, setShowPrescriptionLenses] = useState(false)
  const [showToggle, setShowToggle] = useState(true)
  const translate = useTranslation();
  const dispatch = useDispatch();
  const currencyFormat = useSelector(
    (state: any) => state.config.currencyFormat
  );
  const lensPrice = useSelector(
    (state: any) => state?.mixAndMatch?.lensPrice
  );
  const lensTotalPrice = useSelector(
    (state: any) => state?.mixAndMatch?.lensTotalPrice
  );
  const selectedType = useSelector(
    (state: any) => state?.mixAndMatch?.selectedType
  );
  const currentPackages = useAllCurrentPackages();
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );

  const nonPrescriptionPackages = useMemo(() => {
    if (!currentPackages) return null
    return { ...currentPackages, validRXPackages: currentPackages.validRXPackages.filter(cp => cp.lensPackage.type === "Non_prescription") }
  }, [currentPackages])

  useEffect(() => {
    if (selectedType && selectedType !== "Non_prescription") {
      setShowPrescriptionLenses(true)
    }
  }, [selectedType])

  useEffect(() => {
    // hide toggle if no prescription lenses
    if (!configPackages) return
    if (configPackages?.every(cp => cp.lensPackage.type === "Non_prescription")) {
      dispatch(mixAndMatchActions.setSelectedType("Non_prescription"))
      setShowToggle(false)
    }
    if (!configPackages?.find(cp => cp.lensPackage.type === "Non_prescription")) {
      setShowToggle(false)
      setShowPrescriptionLenses(true)
    }
  }, [configPackages])


  const renderPrice = (value: number) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        prefix={currencyFormat.suffix ? "" : currencyFormat.prefix + " "}
        suffix={currencyFormat.suffix}
        decimalSeparator={currencyFormat.decimalSeparator}
        thousandSeparator={currencyFormat.thousandSeparator}
        decimalScale={
          !isNaN(Number(currencyFormat?.decimalScale))
            ? Number(currencyFormat?.decimalScale)
            : 2
        }
        fixedDecimalScale={true}
        renderText={(value: string) => <>{value}</>}
      />
    );
  };

  return (
    <div className="LensesSection__container">
      <div className="LensesSection__header">
        <div>
          {lensPrice !== 0 && (
            <>
              <span className="LensesSection__container__title">
                {translate("steps.mixAndMatch.lenses")}:
                {lensTotalPrice > lensPrice && <span className='LensesSection__container__price__strikePrice'>{renderPrice(lensTotalPrice)}</span>}
                <span className='LensesSection__container__price'>{renderPrice(lensPrice)}</span>
              </span>
            </>
          )}
        </div>
        {showToggle && (
          <BrandComponent
            componentName="TogglePrescription"
            parameter={{ toggle: setShowPrescriptionLenses, checked: showPrescriptionLenses }}
          ></BrandComponent>
        )}
      </div>
      {!showPrescriptionLenses && (
        <BrandComponent
          componentName='NonPrescriptionLenses'
          parameter={{
            nonPrescriptionPackages: nonPrescriptionPackages
          }}
        />
      )}
      {showPrescriptionLenses && <BrandComponent componentName="PrescriptionLenses" />}
    </div>
  );
}
