import React, { useMemo, useState } from "react";
import "./default.module.scss";
import Image from "../common/Image";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import { useTranslation } from "../../configurator/translations/TranslationHooks";
import { workflow } from "../../configurator/models/Workflow";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { StepBadgeType } from "../StepBadge/types/StepBadgeType";
import { workflowActions } from "../../redux/slices/workflow";
import useDelegatingLearnMore from "../../configurator/hooks/useDelegatingLearnMore";
import { isDesktop, isMobile } from "react-device-detect";
import { AutomaticDiscountParameter } from "../../configurator/models/AutomaticDiscountParameter";
import {
  useCurrentPackages,
  useLargeIcons,
  useLearnMore,
  usePriceDiscountBadge,
} from "@hooks";

export interface StepCardProps {
  title: string;
  description?: string;
  price?: number;
  discount?: number;
  insPrice?: number;
  onClick?: (p: any) => void;
  badge?: StepBadgeType;
  textBadge?: string;
  imageUrl?: string;
  id: string;
  noMarginTitle?: boolean;
  included?: boolean;
  noImageCard?: boolean;
  moreInfo?: Function;
  sequence?: number;
  disabled?: boolean;
  discountLoading?: boolean;
  contentIdentifier?: string;
  ignoreInsurance?: boolean;
  moreInfoTitle?: string;
  transitionId?: string;
  noMobileSpacer?: boolean;
  showSavingsLabel?: boolean;
}

export function StepCard({
  title,
  description,
  price,
  discount,
  insPrice,
  badge,
  textBadge,
  onClick,
  imageUrl,
  id,
  included,
  noImageCard,
  moreInfo,
  disabled,
  discountLoading,
  contentIdentifier,
  ignoreInsurance,
  moreInfoTitle,
  transitionId,
  noMobileSpacer,
  showSavingsLabel,
}: StepCardProps) {
  const currentPackages = useCurrentPackages();
  const currentStep = useSelector(
    (state: any) => state?.workflow?.currentStep?.key
  );
  const { learnMoreVisible, delegateLearnMoreCheck, getMoreInfoTitle } =
    useLearnMore(currentStep, contentIdentifier);
  const { isLargeIconsActive, getIcons } = useLargeIcons();
  const [isMouseOver, setIsMouseOver] = useState(false);
  let promoBadgeID = id;
  let splittedID = promoBadgeID.split("$");
  if (splittedID.length > 1) {
    promoBadgeID = splittedID[1];
  } else {
    promoBadgeID = splittedID[0];
  }

  const promoBadge = usePriceDiscountBadge(promoBadgeID, discount, price);
  const configProjectImage = useSelector(
    (state: any) => state.config.baseURLs.projectImage
  );
  const largeIconBasepath = useSelector(
    (state: any) =>
      `${state.config.baseURLs.assetsCDN || ""}/extra/image/rxc/icons/`
  );
  const translation = useTranslation();
  const dispatch = useDispatch();

  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );

  const onCardClick = () => {
    let isClickable =
      (insuranceEnabled &&
        (price === undefined || price === null || isNaN(price))) ||
      (insPrice !== undefined && insPrice !== null && !isNaN(insPrice));

    if (!insuranceEnabled || isNonPrescriptionAvailable() || isClickable) {
      if (onClick) {
        if (promoBadge) {
          const currentStep = workflow.currentStep;
          const param = new AutomaticDiscountParameter(
            promoBadge.key,
            promoBadge.value,
            {
              key: currentStep.key,
              progressive: currentStep.progressive,
            }
          );
          dispatch(
            workflowActions.setAutomaticDiscountBadgeStepSelection(param)
          );
        }
        onClick(id);
      }
    }
  };

  const getDataElementIdForCard = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-Choose";
  };

  const getDataElementIdForLearnMore = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-LearnMoreOpen";
  };

  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };

  const isBadgeVisible = () => {
    return (
      badge &&
      (!promoBadge ||
        (promoBadge &&
          price !== undefined &&
          price !== null &&
          discount !== undefined &&
          discount !== null))
    );
  };

  const isTopPromoBadgeVisible = () => {
    return (
      promoBadge &&
      (price === undefined || price === null) &&
      (discount === undefined || discount === null)
    );
  };

  const fileNameImages = useMemo(() => {
    return getIcons(transitionId || id);
  }, [id]);

  return (
    <div
      className={"StepCard__container"}
      onClick={onCardClick}
      data-element-id={getDataElementIdForCard()}
      data-description={title}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <button className={"StepCard__card" + (disabled ? "_disabled" : "")}>
        <div className={"StepCard__layout"}>
          <div style={{ display: "flex" }}>
            {disabled && <div className="StepCard__layout__disabled" />}
            {!isLargeIconsActive ? (
              imageUrl &&
              !isMobile && (
                <div
                  className={`StepCard__layout__imgcontainer ${noImageCard ? "noImage" : ""
                    }`}
                >
                  <Image
                    imageClass={`StepCard__layout__imgcontainer__img`}
                    url={configProjectImage}
                    fileName={imageUrl}
                    alt={imageUrl}
                  />
                </div>
              )
            ) : (
              //Large Icons
              <div
                // style={
                //   !isHTML(description)
                //     ? { overflowX: "hidden", borderRadius: "8px" }
                //     : { overflowX: "visible" }
                // }
                className={`StepCard__layout__largeimgcontainer ${noImageCard ? "noImage" : ""
                  }`}
              >
                <>
                  <Image
                    imageClass={`StepCard__layout__largeimgcontainer__largeimg`}
                    url={largeIconBasepath}
                    fileName={fileNameImages?.[0]}
                    alt={imageUrl}
                    style={{
                      display:
                        isMouseOver && fileNameImages?.length === 2
                          ? "none"
                          : "inherit",
                    }}
                  />
                  {fileNameImages?.length === 2 && (
                    <Image
                      imageClass={`StepCard__layout__largeimgcontainer__largeimg`}
                      url={largeIconBasepath}
                      fileName={fileNameImages[1]}
                      alt={imageUrl}
                      style={{
                        display: !isMouseOver ? "none" : "inherit",
                      }}
                    />
                  )}
                </>
              </div>
            )}

            <div
              className={
                !imageUrl
                  ? "StepCard__layout__content spacer"
                  : "StepCard__layout__content"
              }
            >
              <div
                className={
                  badge
                    ? "StepCard__layout__content__title__badgespacer"
                    : "StepCard__layout__content__title"
                }
              >
                {title}

                {isBadgeVisible() && (
                  <BrandComponent
                    componentName="StepBadge"
                    parameter={{ type: badge, text: textBadge }}
                  />
                )}

                {isTopPromoBadgeVisible() && (
                  <div className="StepCard__layout__topBadgeWrapper">
                    <BrandComponent
                      componentName="StepPriceBadge"
                      parameter={{ badge: promoBadge?.badge }}
                    />
                  </div>
                )}
              </div>
              <div className={"StepCard__layout__content__description"}>
                <p dangerouslySetInnerHTML={{ __html: description }} />
              </div>
              {disabled && isDesktop && (
                <>
                  <div className="StepCard__layout__content__spacer"></div>
                  <p id="notAvailable">{translation("notAvailable")}</p>
                </>
              )}
              {!disabled && learnMoreVisible() ? (
                <>
                  <div className={"StepCard__layout__content__spacer desktopOnly"} />
                  <div
                    className={"StepCard__layout__content__learnMore desktopOnly"}
                    onClick={(e) => {
                      e.stopPropagation();
                      delegateLearnMoreCheck();
                    }}
                  >
                    {moreInfoTitle
                      ? translation(moreInfoTitle)
                      : getMoreInfoTitle()}
                  </div>
                  {price != null ? (
                    <div className={"StepCard__layout__content__price desktopOnly"}>
                      {!included ? (
                        <BrandComponent
                          componentName="StepPrice"
                          parameter={{
                            price,
                            discount,
                            insPrice,
                            promo: promoBadge?.badge,
                            ignoreInsurance:
                              isNonPrescriptionAvailable() || ignoreInsurance,
                            discountLoading,
                          }}
                        />
                      ) : (
                        translation("price.included")
                      )}
                    </div>
                  ) : null}
                </>
              ) : (disabled || !moreInfo) &&
                (price === undefined || price === null) ? null : (
                <>
                  <div className={"StepCard__layout__content__spacer desktopOnly"} />
                  <div
                    className={
                      "StepCard__layout__content__learnMorePriceContainer desktopOnly" +
                      (!moreInfo && !showSavingsLabel ? " onlyPrice" : "")
                    }
                  >
                    {moreInfo && (
                      <a
                        className={"StepCard__layout__content__learnMore"}
                        tabIndex={0}
                        onClick={(e) => {
                          e.stopPropagation();
                          moreInfo();
                        }}
                        data-element-id={getDataElementIdForLearnMore()}
                      >
                        {translation("steps.type.moreInfo")}
                      </a>
                    )}
                    {showSavingsLabel && (
                      <BrandComponent componentName="Savings" parameter={{ discount: discount }}></BrandComponent>
                    )}
                    {price != null ? (
                      <div className={"StepCard__layout__content__price"}>
                        {!included ? (
                          <BrandComponent
                            componentName="StepPrice"
                            parameter={{
                              price,
                              discount,
                              insPrice,
                              promo: promoBadge?.badge,
                              ignoreInsurance: isNonPrescriptionAvailable(),
                              discountLoading,
                            }}
                          />
                        ) : (
                          translation("price.included")
                        )}
                      </div>
                    ) : null}
                  </div>
                </>
              )}
              {disabled && isMobile && (
                <>
                  <div className="StepCard__layout__content__spacer"></div>
                  <p id="notAvailable">{translation("notAvailable")}</p>
                </>
              )}
            </div>
          </div>

        </div>
        {
          (included || !isNaN(price)) &&
          noMobileSpacer !== false && (
            <div className={"StepCard__layout__content__spacerMobile mobileOnly"} />
          )}

        <div
          style={{
            padding: `0px 16px 8px ${currentStep === "ProtectionPlan" ? "32px" : "16px"
              }`,
          }}
          className={
            "StepCard__layout__content__learnMorePriceContainer mobileOnly" +
            (!learnMoreVisible() && !showSavingsLabel ? " onlyPrice" : "") +
            (!learnMoreVisible() && !price ? " hide" : "")
          }
        >
          {learnMoreVisible() && (
            <a
              className={"StepCard__layout__content__learnMore"}
              tabIndex={0}
              onClick={(e) => {
                e.stopPropagation();
                delegateLearnMoreCheck();
              }}
              data-element-id={getDataElementIdForLearnMore()}
            >
              {moreInfoTitle
                ? translation(moreInfoTitle)
                : getMoreInfoTitle()}
            </a>
          )}
          {showSavingsLabel && (
            <BrandComponent componentName="Savings" parameter={{ discount: discount }}></BrandComponent>
          )}
          {price != null ? (
            <div className={"StepCard__layout__content__price"}>
              {!included ? (
                <BrandComponent
                  componentName="StepPrice"
                  parameter={{
                    price,
                    discount,
                    insPrice,
                    promo: promoBadge?.badge,
                    ignoreInsurance: isNonPrescriptionAvailable(),
                    discountLoading,
                  }}
                />
              ) : (
                translation("price.included")
              )}
            </div>
          ) : null}
        </div>

      </button>
    </div>
  );
}
