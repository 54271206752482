import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { virtualMirrorActions } from "../../redux/slices/virtualMirror";
import { cloneDeep } from "lodash";


function switchToFirst(arr, value) {
  const index = arr.findIndex(obj => obj.lensIdentifier === value); // Find the index of the object based on property
  if (index > -1) {
    // If the object exists in the array
    const switchedObject = arr[index]; // Store the object to be switched
    const newArr = [
      switchedObject,
      ...arr.slice(0, index), // Objects before the switched object
      ...arr.slice(index + 1) // Objects after the switched object
    ];
    return newArr; // Return the new array with the object at the front
  } else {
    return "Object not found in the array";
  }
}


export function VirtualMirror() {
  const [vmapp, setVmapp] = useState(null);
  const [lensIdentifierFromVmmv, setLensIdentifierFromVmmv] = useState(null);
  const lensesList = useSelector((state: any) => state.virtualMirror.lensesList)
  const vmappConfig = useSelector((state: any) => state.virtualMirror.vmappConfig)
  const lensIdentifier = useSelector((state: any) => state.virtualMirror.lensIdentifier)
  const currentStep = useSelector((state: any) => state.workflow.currentStep.key)
  const initializationStepKey = useSelector((state: any) => state.virtualMirror.initializationStepKey)
  const dispatch = useDispatch();

  useEffect(() => {
    const createVmmv = () => {
      // update configs from redux
      var config = {
        ...cloneDeep(vmappConfig),
        selector: ".vmmv",
        onLensChange: (lensIdentifier) => {
          console.log(
            "onLensChange callback invoked, the lens identifier return is " + lensIdentifier
          );
          //dispatch(virtualMirrorActions.setLensSelectedFromVmmv({ lensIdentifier }));
          setLensIdentifierFromVmmv(lensIdentifier)
        },
      };
      // create vmapp instance
      let vmappInstance = new window.vmmv.VMConfiguratorsWidget(config);
      // set the step key where is the vmapp is initialized
      dispatch(virtualMirrorActions.setInitializationStepKey(currentStep));
      // set instance to local state to call methods on it
      setVmapp(vmappInstance);
      // init/open app
      vmappInstance.init();
    };

    createVmmv();

  }, []);


  useEffect(() => {
    if (vmapp) {
      vmapp.setLensList({
        lensList: switchToFirst(lensesList, lensIdentifier)
      })
    }
  }, [lensesList, vmapp])

  useEffect(() => {
    if (vmapp) {
      vmapp.setLens(lensIdentifier)
    }
  }, [lensIdentifier, vmapp])

  useEffect(() => {
    // close vm on step change
    if (currentStep !== initializationStepKey && vmapp) {
      handleClose();
    }
  }, [currentStep, vmapp])

  const handleClose = () => {
    vmapp.unmount().then(() => {
      dispatch(virtualMirrorActions.setShowVirtualMirror(false));
    });
  };

  const handleSelectAndClose = () => {
    const selectedLensfromVmmv = lensesList.find(l => l.lensIdentifier === lensIdentifierFromVmmv)
    if (selectedLensfromVmmv) {
      const swatchEl = document.querySelector(`[data-vmmv="${selectedLensfromVmmv.name + selectedLensfromVmmv.lensTechnology}"]`)
      console.log(swatchEl)
      //select swatch
      if (swatchEl) swatchEl.click();
    }

    handleClose();
  }

  return (
    <div className="vmmv-container">
      <button
        className="close"
        onClick={handleSelectAndClose}
        data-element-id="LensPanel_TreatmentDetail-VMBackToProduct"
        data-description="BACK TO PRODUCT"
      >
        {/* <div className="close__icon" />
        BACK TO PRODUCT */}
        <svg width="2em" height="2em" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_27)"><g filter="url(#dlofwdnq9a)"><path d="M18.5345 4.00086L11.5704 10.965L4.60632 4L4.00086 4.60546L10.965 11.5704L4 18.5345L4.60546 19.14L11.5704 12.1759L18.5345 19.14L19.14 18.5345L12.1759 11.5704L19.14 4.60632L18.5345 4.00086V4.00086Z" fill="black" stroke="black" stroke-width="0.8"></path><path d="M18.5345 4.00086L11.5704 10.965L4.60632 4L4.00086 4.60546L10.965 11.5704L4 18.5345L4.60546 19.14L11.5704 12.1759L18.5345 19.14L19.14 18.5345L12.1759 11.5704L19.14 4.60632L18.5345 4.00086V4.00086Z" fill="black" stroke="black" stroke-width="0.8"></path></g><path d="M18.5345 4.00086L11.5704 10.965L4.60632 4L4.00086 4.60546L10.965 11.5704L4 18.5345L4.60546 19.14L11.5704 12.1759L18.5345 19.14L19.14 18.5345L12.1759 11.5704L19.14 4.60632L18.5345 4.00086V4.00086Z" fill="white" stroke="#fff" stroke-width="0.8"></path><path d="M18.5345 4.00086L11.5704 10.965L4.60632 4L4.00086 4.60546L10.965 11.5704L4 18.5345L4.60546 19.14L11.5704 12.1759L18.5345 19.14L19.14 18.5345L12.1759 11.5704L19.14 4.60632L18.5345 4.00086V4.00086Z" fill="white" stroke="#fff" stroke-width="0.8"></path></g><defs><filter id="dlofwdnq9a" width="24" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend><feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur_1_27"></feGaussianBlur></filter></defs></svg>
      </button>
      <div className="vmmv"></div>
    </div>
  );
}
