import React from "react";
import { useSelector } from "react-redux";
import { useDefaultLogoIcons, useProductImage } from "@hooks";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { BrandComponent } from "../common/BrandComponent";
import HtmlParser from "html-react-parser";
import "./default.module.scss";
import Image from "../common/Image";
import { isMobile } from "react-device-detect";
import { useConfigContent } from "@hooks";
interface ProductContainerProps {
  productName?: string;
}

export function ProductContainer({ productName }: ProductContainerProps) {
  const brandImageUrl = useSelector(
    (state: any) => state.config?.data?.frame?.brandImageUrl
  );
  const { urlImage: productImageUrl } = useProductImage();
  const defaultLogoIcon = useDefaultLogoIcons();
  const isLoading = useSelector((state: any) => state.color.isLoading);
  const brand = useSelector((state: any) => state.config?.data?.frame?.brand);
  const name = useSelector((state: any) => state.config?.data?.frame?.name);
  const size = useSelector((state: any) => state.config?.data?.frame?.size);
  const lensTransition = useSelector(
    (state: any) => state.color.transitionSelected
  );
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );
  const colorSelected = useSelector((state: any) => state.color.colorSelected);
  const configColor = useConfigContent("Color");
  const frameSizeLabel = useTranslate("frameSize");
  const showFallbackImageMessage = useSelector(
    (state: any) => state.color?.showFallbackImageMessage
  );
  const swatchImage = useSelector((state: any) => state.color.swatchImage);

  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const fallbackImageMessageTitle = useTranslate("fallbackImageMessageTitle");
  const fallbackImageMessageSubTitle = useTranslate(
    "fallbackImageMessageSubtitle"
  );

  return (
    <div className={"ProductContainer__container"}>
      <div className={"ProductContainer__topBrandContainer"}>
        {(!brandImageUrl || brandImageUrl === "") && (
          <img
            className={"ProductContainer__defaultTopBrandImage"}
            src={defaultLogoIcon}
          />
        )}
        {brandImageUrl && (
          <div className={"ProductContainer__topBrandImage"}></div>
        )}
      </div>
      <div className={"ProductContainer__productImageContainer"}>
        <div className="ProductContainer__productImageContainer__productImageContent">
          {isLoading ? (
            <BrandComponent
              componentName="Loader"
              parameter={{ greyLoader: true }}
            />
          ) : (
            <>
              {!lensTransition ? (
                !isMobile && (
                  <img
                    className={
                      "ProductContainer__productImageContainer__productImageContent__productImage"
                    }
                    alt={brand + " " + name}
                    src={productImageUrl}
                  />
                )
              ) : (
                <BrandComponent
                  componentName="LensPreviewTransition"
                  parameter={{ lensImage: lensTransition }}
                />
              )}
              {showFallbackImageMessage &&
                configProjectImage &&
                currentStep?.toLowerCase() === "lenscolor" && (
                  <BrandComponent
                    componentName="LensPreviewFallback"
                    parameter={{
                      selectedColor: colorSelected,
                      configColor: configColor,
                      isLoading: isLoading,
                    }}
                  />
                )}
            </>
          )}
        </div>
        <div className={"ProductContainer__bottomBrandContainer"}>
          {brandImageUrl && brandImageUrl !== "" && (
            <img
              className={"ProductContainer__bottomBrandContainer__productBrand"}
              src={brandImageUrl}
              alt={brand}
            />
          )}
          <div
            className={
              "ProductContainer__bottomBrandContainer__productModelContainer"
            }
          >
            <div
              className={
                "ProductContainer__bottomBrandContainer__productModelContainer__model"
              }
            >
              {name}
            </div>
            {size ? (
              <div
                className={
                  "ProductContainer__bottomBrandContainer__productModelContainer__frameSize"
                }
              >
                {frameSizeLabel}: {size}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
