import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

export type VirtualMirror = {
  showVirtualMirror?: boolean;
  transitionsTechnologiesMap: {};
  transitionsSwatchesMap: [];
  lensesList: [];
  lensIdentifier: string;
  vmappConfig: {};
  initializationStepKey: string;
};

const initialState: VirtualMirror = {
  showVirtualMirror: false,
  transitionsTechnologiesMap: {},
  transitionsSwatchesMap: [],
  lensesList: [],
  lensIdentifier: "",
  vmappConfig: {},
  initializationStepKey: "",
};

const virtualMirrorSlice = createSlice({
  name: "virtualMirror",
  initialState: initialState,
  reducers: {
    setVmappConfig: (state, { payload }: PayloadAction<any>) => {
      state.vmappConfig = payload;
    },
    setInitializationStepKey: (state, { payload }: PayloadAction<any>) => {
      state.initializationStepKey = payload;
    },
    setShowVirtualMirror: (state, { payload }: PayloadAction<any>) => {
      state.showVirtualMirror = payload;
    },
    setTransitionsTechnologiesMap: (state, { payload }: PayloadAction<any>) => {
      state.transitionsTechnologiesMap = payload;
    },
    setTransitionsSwatchesMap: (state, { payload }: PayloadAction<any>) => {
      state.transitionsSwatchesMap = payload;
    },
    setLens: (state, { payload }: PayloadAction<any>) => {
      const color = payload.color
      const lensIdentifier = state.lensesList.find(l => l.swatchImage === color.swatchImage)?.lensIdentifier
      if (!lensIdentifier || state.lensIdentifier === lensIdentifier) return
      state.lensIdentifier = lensIdentifier
    },
    setLensesList: (state, { payload }: PayloadAction<any>) => {
      // console.log(payload)
      // console.log(current(state.transitionsTechnologiesMap))
      // console.log(current(state.transitionsSwatchesMap))
      let lensTechnology = payload.id.split("$")[1];
      if (!payload.id.split("$")[1]) {
        // capitalize first letter in case of "transition" (non-prescription)
        lensTechnology = payload.id.charAt(0).toUpperCase() + payload.id.slice(1);
      }
      const lensesList = payload.colors
        .map((color) => {
          const lensIdentifier = state.transitionsSwatchesMap.find(s => s.lensName === state.transitionsTechnologiesMap[lensTechnology] && (s.clen && s.clen === color.clen))?.colorCode
          return {
            lensIdentifier: lensIdentifier,
            name: color.title,
            colorName: color.title,
            swatchImage: color.swatchImage,
            clen: color.clen,
            lensTechnology: payload.id
          }
        })
        .filter(s => s.lensIdentifier)

      if (state.lensesList.length === lensesList.length) {
        return
      }

      console.log(lensesList)
      state.lensesList = lensesList
    }
  },
});

export const virtualMirrorReducer = virtualMirrorSlice.reducer;
export const virtualMirrorActions = virtualMirrorSlice.actions;
