import React, { useState, useEffect, Fragment } from "react";
import {
  useTranslation,
  useTranslate,
} from "../../configurator/translations/TranslationHooks";
import "./default.module.scss";
import { Prescription, ShortPrescription } from "../../types";
import { isDesktop, isMobile } from "react-device-detect";
import { useCurrentBrand, useEnableAccordionReview } from "@hooks";

export interface ReadOnlyTableProps {
  prescription: ShortPrescription;
  open?: boolean;
  prismValue?: boolean;
}

export const ReadOnlyTable = (props: ReadOnlyTableProps) => {
  const translation = useTranslation();
  const sph = useTranslate("steps.advancedPrescription.manual.sphere");
  const cyl = useTranslate("steps.advancedPrescription.manual.cylinder");
  const axis = useTranslate("steps.advancedPrescription.manual.axis");
  const add = useTranslate("steps.advancedPrescription.manual.add");
  const vertical = useTranslate("steps.advancedPrescription.manual.vertical");
  const baseDirection = useTranslate(
    "steps.advancedPrescription.manual.baseDirection"
  );
  const horizontal = useTranslate(
    "steps.advancedPrescription.manual.horizontal"
  );
  const rightEyeInitials = useTranslate(
    "steps.advancedPrescription.manual.rightEye.initials"
  );
  const rightEyeName = useTranslate(
    "steps.advancedPrescription.manual.rightEye.name"
  );
  const right = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.right"
  );
  const left = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.left"
  );
  const leftEyeInitials = useTranslate(
    "steps.advancedPrescription.manual.leftEye.initials"
  );
  const leftEyeName = useTranslate(
    "steps.advancedPrescription.manual.leftEye.name"
  );

  const enableAccordionReview = useEnableAccordionReview();

  const brand = useCurrentBrand();

  return (
    <div
      className="ReadOnlyTable__container"
      style={
        props.open
          ? {
            marginTop: isMobile ? "16px" : "0px",
            transform: "scale(1)",
          }
          : {
            transform: "scale(0.00000)",
            height: "0px",
            margin: "0px"
          }

      }
    >
      {!props.prismValue && (
        <>
          <div
            style={
              isMobile
                ? { display: "flex" }
                : { display: "flex", flexDirection: "column" }
            }
          >
            <div className="ReadOnlyTable__container__top">
              <div className="ReadOnlyTable__container__top__cell"></div>
              <div className="ReadOnlyTable__container__top__cell">{sph}</div>
              <div className="ReadOnlyTable__container__top__cell">{cyl}</div>
              <div className="ReadOnlyTable__container__top__cell">{axis}</div>
              {props.prescription.ADD?.OD && (
                <div className="ReadOnlyTable__container__top__cell">{add}</div>
              )}
            </div>
            <div className="ReadOnlyTable__container__right">
              <div style={{ display: "flex", flex: isMobile ? 1 : "unset" }}>
                <div className="ReadOnlyTable__container__right__cell">
                  {rightEyeInitials + " " + rightEyeName}
                </div>
                <div className="ReadOnlyTable__container__right__cellValue">
                  {Number(props.prescription.SPH?.OD).toFixed(2)}
                </div>
                <div className="ReadOnlyTable__container__right__cellValue">
                  {Number(props.prescription.CYL?.OD).toFixed(2)}
                </div>
                <div className="ReadOnlyTable__container__right__cellValue">
                  {props.prescription.AX?.OD}
                </div>
                {props.prescription.ADD?.OD && (
                  <div className="ReadOnlyTable__container__right__cellValue">
                    {props.prescription.ADD?.OD}
                  </div>
                )}
              </div>
              <div style={{ display: "flex", flex: isMobile ? 1 : "unset" }}>
                <div className="ReadOnlyTable__container__right__cell">
                  {leftEyeInitials + " " + leftEyeName}
                </div>
                <div className="ReadOnlyTable__container__right__cellValue">
                  {props.prescription.SPH?.OS
                    ? Number(props.prescription.SPH?.OS).toFixed(2)
                    : "0.00"}
                </div>
                <div className="ReadOnlyTable__container__right__cellValue">
                  {props.prescription.CYL?.OS
                    ? Number(props.prescription.CYL?.OS).toFixed(2)
                    : "0.00"}
                </div>
                <div className="ReadOnlyTable__container__right__cellValue">
                  {props.prescription.AX?.OS ? props.prescription.AX.OS : "-"}
                </div>
                {props.prescription.ADD?.OD && (
                  <div className="ReadOnlyTable__container__right__cellValue">
                    {props.prescription.ADD?.OS
                      ? props.prescription.ADD?.OS
                      : "-"}
                  </div>
                )}
              </div>
            </div>
          </div>
          {props.prescription.PD.OD !== null && !props.prescription.PD.OS ? (
            <div className="ReadOnlyTable__container__pd__container">
              <div className={"ReadOnlyTable__container__pd__container__label"}>
                Pupillary Distance
              </div>
              <div
                className={"ReadOnlyTable__container__pd__container__value"}
                style={isMobile ? { flex: "2" } : {}}
              >
                {props.prescription?.PD?.OD}
              </div>
            </div>
          ) : (
            <div className="ReadOnlyTable__container__pd__containerDouble">
              <div
                style={!isMobile ? {
                  width: "100%",
                  height: "100%",
                  justifyContent: "flex-start",
                } : {
                  width: "100%",
                  height: "100%",
                  // justifyContent: "flex-start",
                  // display: "grid",
                  // gridTemplateColumns: "100px 1fr 1fr"
                }}
              >
                <div className="ReadOnlyTable__container__pd__containerDouble__label"></div>
                <div className="ReadOnlyTable__container__pd__containerDouble__value">
                  {right}
                </div>
                <div className="ReadOnlyTable__container__pd__containerDouble__value">
                  {left}
                </div>
              </div>
              <div style={!isMobile ? {
                justifyContent: "flex-start",
              } : {
                display: "grid",
                //gridTemplateColumns: "100px 1fr 1fr",
                gridAutoFlow: "column"
              }}>
                <div
                  className={"ReadOnlyTable__container__pd__container__label"}
                >
                  Pupillary Distance
                </div>
                <div
                  className={"ReadOnlyTable__container__pd__container__value"}
                >
                  {props.prescription.PD.OD}
                </div>
                <div
                  className={"ReadOnlyTable__container__pd__container__value"}
                >
                  {props.prescription.PD.OS}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {props.prescription.PRISM_ENABLED && props.prismValue && (
        <div
          style={isMobile ? { display: "flex" } : { display: "flex", flexDirection: "column" }}
        >
          <div className="ReadOnlyTable__container__top">
            <div className="ReadOnlyTable__container__top__cell"></div>
            <div className="ReadOnlyTable__container__top__cell">
              {vertical}
            </div>
            <div className="ReadOnlyTable__container__top__cell">
              {baseDirection}
            </div>
            <div className="ReadOnlyTable__container__top__cell">
              {horizontal}
            </div>
            <div className="ReadOnlyTable__container__top__cell">
              {baseDirection}
            </div>
          </div>
          <div className="ReadOnlyTable__container__right">
            <div style={{ display: "flex", flex: isMobile ? 1 : "unset" }}>
              <div className="ReadOnlyTable__container__right__cell">
                {rightEyeInitials + " " + rightEyeName}
              </div>
              <div className="ReadOnlyTable__container__right__cellValue">
                {Number(props.prescription.VPRISM?.OD).toFixed(2)}
              </div>
              <div className="ReadOnlyTable__container__right__cellValue">
                {props.prescription.VBASEDIR?.OD}
              </div>
              <div className="ReadOnlyTable__container__right__cellValue">
                {Number(props.prescription.HPRISM?.OD).toFixed(2)}
              </div>
              <div className="ReadOnlyTable__container__right__cellValue">
                {props.prescription.HBASEDIR?.OD}
              </div>
            </div>
            <div style={{ display: "flex", flex: isMobile ? 1 : "unset" }}>
              <div className="ReadOnlyTable__container__right__cell">
                {leftEyeInitials + " " + leftEyeName}
              </div>
              <div className="ReadOnlyTable__container__right__cellValue">
                {Number(props.prescription.VPRISM?.OS).toFixed(2)}
              </div>
              <div className="ReadOnlyTable__container__right__cellValue">
                {props.prescription.VBASEDIR?.OS}
              </div>
              <div className="ReadOnlyTable__container__right__cellValue">
                {Number(props.prescription.HPRISM?.OS).toFixed(2)}
              </div>
              <div className="ReadOnlyTable__container__right__cellValue">
                {props.prescription.HBASEDIR?.OS}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
