import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { virtualMirrorActions } from "../../redux/slices/virtualMirror";
import { env } from "../ConfigLoader"

export function useVirtualMirror() {
  const vmmvtransitionsMapUrl = useSelector((state: any) => !state.config?.baseURLs?.assetsCDN ? null : `${state.config.baseURLs.assetsCDN}/extra/image/rxc/rxc_settings/rxc2vmmv_transitions_map.json`)
  const vmmvModule = useSelector((state: any) => state.config?.vmmvModule)
  const dispatch = useDispatch();


  useEffect(() => {
    // fetch TransitionsTechnologiesMap 
    async function fetchTransitionsTechnologiesMap() {
      try {
        const { data } = await axios.get(vmmvtransitionsMapUrl || "")
        dispatch(virtualMirrorActions.setTransitionsTechnologiesMap(data))
      } catch (err) {
        console.log(err)
      }
    }
    // fetch TransitionsSwatchesMap
    async function fetchTransitionsSwatchesMap() {
      const environment = env()
      const transitionsSwatchesUrl = environment === "prod" ? "https://vmmv.luxottica.com/assets/transitions/transitions-swatches.json" : "https://vmmv-uat.luxottica.com/assets/transitions/transitions-swatches.json"
      try {
        const { data } = await axios.get(transitionsSwatchesUrl)
        dispatch(virtualMirrorActions.setTransitionsSwatchesMap(data))
      } catch (err) {
        console.log(err)
      }
    }

    if (vmmvModule?.vmExperience && vmmvtransitionsMapUrl) {
      fetchTransitionsTechnologiesMap()
      fetchTransitionsSwatchesMap()
    }
  }, [vmmvModule, vmmvtransitionsMapUrl])



  useEffect(() => {
    // set initial config from vmmvModule
    if (vmmvModule?.vmExperience) {
      dispatch(virtualMirrorActions.setVmappConfig(vmmvModule))
    }
  }, [vmmvModule])

}