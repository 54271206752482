import React, { useEffect, useMemo, useState } from 'react';
import "./default.module.scss";
import { BrandComponent } from '../common/BrandComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useConfigContent, useCurrentPrices } from '@hooks';
import NumberFormat from "react-currency-format";
import { configActions } from '../../redux/slices/config';
import { colorActions } from '../../redux/slices/color';
import { mixAndMatchActions } from '../../redux/slices/mixAndMatch';
import { useTranslation } from '../../configurator/translations/TranslationHooks';


export function FrameSection({ getPackages }) {
  const frameColors = useConfigContent("frameColor");
  const [selectedFrame, setSelectedFrame] = useState(null)
  const translate = useTranslation();

  const framePrice = useSelector(
    (state: any) => state?.mixAndMatch?.framePrice
  );

  const currencyFormat = useSelector(
    (state: any) => state.config.currencyFormat
  );
  const loading = useSelector(
    (state: any) => state?.mixAndMatch?.loadingPackages
  );

  const frames = useSelector(
    (state: any) => state.config.data.frames
  );
  const frame = useSelector(
    (state: any) => state.config.data.frame
  );
  const reduxDispatch = useDispatch();

  const getFrame = (colorId, callGetPackages?: boolean) => {
    const foundFrameColor = frames.length > 0 ? frames?.find(entry => entry.color === colorId) : frame
    if (!foundFrameColor) return
    reduxDispatch(mixAndMatchActions.setSelectedFrame(foundFrameColor))
    reduxDispatch(mixAndMatchActions.setFramePrice(Number(foundFrameColor?.listPrice)))
    reduxDispatch(configActions.changeFrame(foundFrameColor));
    reduxDispatch(colorActions.reset())
    let frameImgUrl = ""
    if (foundFrameColor?.imageUrl?.includes("bgc")) {
      frameImgUrl = foundFrameColor?.imageUrl.split("bgc")[0] + "bgc=%23ffffff"
    }
    reduxDispatch(colorActions.setFrameImgUrl(frameImgUrl));
    if (callGetPackages) {
      getPackages(foundFrameColor)
    }
  }

  useEffect(() => {
    if (!selectedFrame) {
      if (frameColors && frameColors.length > 0 && frame) {
        let selectedFrameFromPDP = null
        if (frames && frames.length > 0) {
          selectedFrameFromPDP = frames?.find(entry => entry.color === frame.color)
        } else {
          selectedFrameFromPDP = frame
        }
        if (selectedFrameFromPDP) {
          const preselectedFrameColor = frameColors.find(entry => entry.id === selectedFrameFromPDP?.color)
          if (preselectedFrameColor) {
            setSelectedFrame(preselectedFrameColor)
            getFrame(preselectedFrameColor.id, false)
          }
        }
      }
    }
  }, [frame, frames, selectedFrame, frameColors])

  const renderPrice = (value: number) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        prefix={currencyFormat.suffix ? "" : currencyFormat.prefix + " "}
        suffix={currencyFormat.suffix}
        decimalSeparator={currencyFormat.decimalSeparator}
        thousandSeparator={currencyFormat.thousandSeparator}
        decimalScale={
          !isNaN(Number(currencyFormat?.decimalScale))
            ? Number(currencyFormat?.decimalScale)
            : 2
        }
        fixedDecimalScale={true}
        renderText={(value: string) => <>{value}</>}
      />
    );
  };

  const onSelectFrameCard = (color) => {
    setSelectedFrame(color)
    getFrame(color.id, true)
  }

  const frameColorsFilteredByTitle = useMemo(() => {
    const uniqueTitles = {}
    return frameColors.filter(color => {
      if (!uniqueTitles[color?.title]) {
        uniqueTitles[color?.title] = true;
        return true;
      }
      return false;
    })
  }, [frameColors])

  return (
    <div className='FrameSection__container'>
      <span className="FrameSection__container__title">{translate("steps.mixAndMatch.frame")}:<span className="FrameSection__container__price">{renderPrice(framePrice)}</span></span>
      <BrandComponent componentName='Carousel'>
        {
          frameColorsFilteredByTitle?.map((color, i) => (
            <BrandComponent
              key={color.id}
              componentName='FrameCard'
              parameter={{
                ...color,
                selected: color.title === selectedFrame?.title,
                onSelectFrameCard: () => onSelectFrameCard(color),
                loading: loading
              }}
            />
          ))
        }
      </BrandComponent>
    </div>
  )
}
