import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../../../components/common/BrandComponent";
import { getStepBadgeType } from "../../ConfigLoader";
import { useBundlesPrices, useContextualMessage } from "@hooks";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import { StepComponentOptions } from "../StepComponent";
import { modalsActions } from "../../../redux/slices/modals";
import "./default.module.scss";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { useTypes } from "@hooks";
import { StepBadgeType } from "../../../components/StepBadge/types/StepBadgeType";
import { configActions } from "../../../redux/slices/config";
import { useCurrentPackages } from "../../hooks";

/**
 * This component displays the 'type' step. It uses the
 * ```StepComponentOptions``` to work in his flow.
 */
export function Type(props: StepComponentOptions) {
  const translation = useTranslation();
  const titleMobile = translation(
    props.brand === "osi"
      ? "steps.color.title"
      : props.brand === "opsm"
        ? "steps.type.titleMobile"
        : "steps.type.title"
  );
  const configPrescription = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const prescriptionTypes = useSelector(
    (state: any) => state.config?.prescriptionTypes
  );
  const ctxMessage = useSelector(
    (state: any) => state?.config?.ctxMessages?.type
  );
  const { types, onSelect } = useTypes();

  const reduxDispatch = useDispatch();
  let s: StepBadgeType = StepBadgeType.BEST_PRESCRIPTION;
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const bundlesEnabled = useBundlesPrices();
  const currentPackages = useCurrentPackages();

  useEffect(() => {
    if(types.length > 0) {
      const nonPrescriptionPkgsCheck = types.filter((type:any) => type.id === "Non_prescription")
      const nonPrescriptionPkgs = currentPackages.filter((pkg:any) => pkg?.lensPackage?.type === "Non_prescription")
      if(nonPrescriptionPkgsCheck.length > 0) {
        console.log(nonPrescriptionPkgs);
        reduxDispatch(configActions.setNonPrescriptionPkgs({check:true,pkgs:nonPrescriptionPkgs}));
      }
    }
  },[types])

  // useEffect(() => {
  //   if (insuranceEnabled && bundlesEnabled)
  //     setTimeout(() => {
  //       reduxDispatch(modalsActions.setInsuranceBundlesModal({ show: true }))
  //     }, 1000)
  // }, [insuranceEnabled, bundlesEnabled])

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translation("steps.type.title"),
        subtitle: ctxMessage,
        titleMobile: titleMobile,
      }}
    >
      <BrandComponent
        componentName="SkipContainer"
        parameter={{
          stepName: "Type",
          onNext: (selected: any, skipped?: boolean) => {
            onSelect(selected?.id, skipped);
          },
          parameterPreselection: ["type"],
          onPreselect: (selected, skipped: boolean) => {
            onSelect(selected.type, skipped, true);
          },
        }}
      >
        {
          types.map((type, i) => (
            <BrandComponent key={i} componentName="StepCard" parameter={type} />
          ))}
      </BrandComponent>
      {configPrescription &&
        configPrescription.prescriptionType === prescriptionTypes.SIMPLE && (
          <div className="Type__prescriptionContainer">
            <BrandComponent
              componentName="StepTitle"
              parameter={{
                title: translation("steps.type.prescriptionTitle"),
              }}
            />
            <BrandComponent
              componentName="StepCard"
              parameter={{
                title: translation("steps.prescription.title"),
                description: translation("steps.prescription.description"),
                imageUrl: "",
                id: "prescriptionCard",
                onClick: () => {
                  reduxDispatch(
                    modalsActions.setOpenPrescriptionInputModal(true)
                  );
                },
              }}
            />
          </div>
        )}
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
